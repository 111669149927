import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import TextFields from "../../controller/TextFields";
import Boutons from "../../controller/Boutons";
import {
  Close,
  GitHub,
  LinkedIn,
  Numbers,
  Person,
  PostAdd,
  Twitter,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadAndDisplayImage from "../../controller/UploaderImage";
import { useState } from "react";
import { Stack } from "@mui/system";
import useActionNosValeurs from "../../utils/hooks/NosValeur/useActionNosValeurs";
import useActionCatgorie from "../../utils/hooks/Categories/useActionCategories";

export default function InputCategoris({ dataselect }) {
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { message, loading, save, update, setMessage, setLoading } =
    useActionCatgorie();
  const [loading1, setLoading1] = useState(true);

  const validate = useFormik({
    enableReinitialize: false,
    initialValues: {
      designation: dataselect?.designation,
    },
    validationSchema: Yup.object().shape({
      designation: Yup.string().required("le champs et obligatoire"),
    }),
    onSubmit: (e) => {
      if (dataselect === null) {
        setLoading1(false);
        save({ data: e });
      }
      if (dataselect) {
        setLoading1(false);
        update({
          data: e,
          // selectedImage: selectedImage,
          idnosvaleur: dataselect?.idcategoriephoto,
        });
      }
    },
  });

  const closeMessage = () => {
    setLoading1(true);
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading1 === true ? (
        ""
      ) : (
        <Typography
          variant="h6"
          textAlign={"center"}
          sx={{ boxShadow: "1px 2px 25px 1px red", mt: 2 }}
          fontWeight={"bold"}
          fontFamily={"Montserrat"}
          color={"#AFAFAF"}
          borderRadius={2}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            m={2}
            alignItems={"center"}
          >
            {message}
            <IconButton onClick={() => closeMessage()}>
              <Close />
            </IconButton>
          </Stack>
        </Typography>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          validate.handleSubmit();
          return null;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12} xl={12} md={12} sm={12}>
            <TextFields
              label={"Designation de la catégories"}
              id={"designation"}
              value={validate.values.designation}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              icons={
                <PostAdd
                  sx={{
                    fontSize: 30,
                    // mt: -10,
                    color: "#192C56",
                  }}
                  fontSize="large"
                />
              }
            />
            {validate.touched.designation && validate.values.designation ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.designation}
                </Typography>
              </div>
            )}

            <div
              style={{
                marginTop: 20,
                width: "100%",
                // background: "red",
                marginLeft: 29,
              }}
            >
              <Boutons
                title={dataselect === null ? "Enregistrer" : "Modification"}
                width={"90%"}
                type={"submit"}
                disabled={loading}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
