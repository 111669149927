import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import ITextFieldnput from "../../components/controller/TextFields";
import { useFormik } from "formik";
import * as yup from "yup";
import Butons from "../../components/controller/Boutons";
import { Telegram } from "@mui/icons-material";
import TitleHeader from "../../components/Menu/TitleHeader";
import { Navigation } from "../navigation";
import { Contact } from "../contact";
import { request } from "../../controller/Request";

function Contacter() {
  const [desables, setDisables]=useState(false)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      message: "",
      tile: "",
    },
    validationSchema: yup.object({
      email: yup.string().required("is require").email(),
      message: yup.string().required("is require"),
    }),
    onSubmit: (e, { resetForm }) => {
      setDisables(true)
      request
        .post("sendmail/send", {
          from: e.email,
          subject: e.tile,
          text: e.message,
        })
        .then((ex) => {
          setDisables(false)
          alert("envoie");
          resetForm();
        })
        .catch((error) => {
          alert(error);
          setDisables(false)
        });
    },
  });

  return (
    <div
      style={{
        marginTop: 40,
        background: "#fff",
      }}
    >
      <Navigation />
      <Grid
        container
        // mt={-5}
        display={{
          xs: "none",
          md: "flex",
          sm: "flex",
          lg: "flex",
        }}
      >
        <Grid container>
          <TitleHeader
            title={"Nous Contacter"}
            desicription={
              "Maitenant que vous savez ce que nous faisons... Dite nous en quoi nous pouvons vous aider!"
            }
          />

          <Stack minHeight={"50%"} width={"50%"} m={5}>
            <ITextFieldnput
              value={validation.values.email}
              placeholder={"email"}
              id={"email"}
              type={"email"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              error={
                validation.errors.email && validation.touched.email
                  ? true
                  : false
              }
              label={"Email"}
            />
            <p
              style={{
                fontSize: 9,
                color: "red",
                marginTop: -1,
              }}
            >
              {validation.errors.email && validation.touched.email
                ? validation.errors.email
                : ""}
            </p>
            <ITextFieldnput
              value={validation.values.tile}
              placeholder={"tile"}
              id={"tile"}
              // type={"email"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              // error={
              //   validation.errors.email && validation.touched.email ? true : false
              // }
              label={"Objet"}
            />
            <ITextFieldnput
              value={validation.values.message}
              placeholder={"message"}
              id={"message"}
              multiline={true}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              error={
                validation.errors.message && validation.touched.message
                  ? true
                  : false
              }
              texterror={
                validation.errors.message && validation.touched.message
                  ? validation.errors.message
                  : ""
              }
              label={"Message"}
            />
            <p
              style={{
                fontSize: 9,
                color: "red",
                marginTop: -1,
              }}
            >
              {validation.errors.message && validation.touched.message
                ? validation.errors.message
                : ""}
            </p>

            <Stack flexDirection={"row"} justifyContent={"end"} mb={5}>
              <Butons
                icons={<Telegram />}
                onClick={validation.handleSubmit}
                title={"Envoie"}
                width={100}
                disabled={desables}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {
        //cote mobiel
      }

      <Grid
        container
        display={{
          xs: "flex",
          md: "none",
          sm: "flex",
          lg: "none",
        }}
      >
        <Grid container>
          <TitleHeader
            title={"Nous Contacter"}
            desicription={
              "Maitenant que vous savez ce que nous faisons... Dite nous en quoi nous pouvons vous aider!"
            }
            widthtexte={"100%"}
          />

          <Stack minHeight={"50%"} width={"100%"} m={5}>
            <ITextFieldnput
              value={validation.values.email}
              placeholder={"email"}
              id={"email"}
              type={"email"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              error={
                validation.errors.email && validation.touched.email
                  ? true
                  : false
              }
              label={"Email"}
            />
            <p
              style={{
                fontSize: 9,
                color: "red",
                marginTop: -1,
              }}
            >
              {validation.errors.email && validation.touched.email
                ? validation.errors.email
                : ""}
            </p>
            <ITextFieldnput
              value={validation.values.tile}
              placeholder={"tile"}
              id={"tile"}
              // type={"email"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              // error={
              //   validation.errors.email && validation.touched.email ? true : false
              // }
              label={"Objet"}
            />
            <ITextFieldnput
              value={validation.values.message}
              placeholder={"message"}
              id={"message"}
              multiline={true}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              error={
                validation.errors.message && validation.touched.message
                  ? true
                  : false
              }
              texterror={
                validation.errors.message && validation.touched.message
                  ? validation.errors.message
                  : ""
              }
              label={"Message"}
            />
            <p
              style={{
                fontSize: 9,
                color: "red",
                marginTop: -1,
              }}
            >
              {validation.errors.message && validation.touched.message
                ? validation.errors.message
                : ""}
            </p>

            <Stack flexDirection={"row"} justifyContent={"end"} mt={2} mb={5}>
              <Butons
                icons={<Telegram />}
                onClick={validation.handleSubmit}
                title={"Envoie"}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Contact />
    </div>
  );
}

export default Contacter;
