import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { json, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { dataItemDrawer } from "../../data/Data";
import Dialogs from "../controller/Dialogs";
import Boutons from "../controller/Boutons";
import imgheaders from "../../img/HTlarge.png";
import { useEffect } from "react";
import VisibleCashRoutChec from "../utils/VisibleCahsRouter/VisibleCashRoutChec";
import { urlbase2 } from "../../controller/Request";
import {
  ArrowDropDown,
  ArrowDropUp,
  ChevronLeftRounded,
} from "@mui/icons-material";
import { useState } from "react";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MenuSiteVitrine() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const nav = useNavigate();
  const [opensD, serTopnesD] = React.useState(false);
  const visiblechek = VisibleCashRoutChec();
  const data = JSON.parse(localStorage.getItem("data"));
  const [open1, setOpen2] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const naviga = (e, text) => {
    setOpen2(false);
    try {
      if (text === "Paramètre") {
        setOpen2(!open1);
      } else {
        setOpen2(false);
        nav(e);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const Logout = () => {
    serTopnesD(!opensD);
    // nav("/");
  };

  const naviga2 = (e) => {
    setOpen2(true);
    nav(e);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        open={open}
        elevation={0}
        sx={{
          bgcolor: "#192C56",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography
              fontFamily={"Montserrat"}
              fontSize={20}
              fontWeight={"bold"}
              noWrap
              component="div"
            >
              Site vitrine
            </Typography>
            <Typography>
             {
              /*
              //
               <Boutons title={"Deconnection"} onClick={() => Logout()} />
              */
             }
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            sx={{
              //   bgcolor: "red",
              width: "100%",
              borderRadius: 0,
              ":hover": {
                bgcolor: "#FFF",
              },
            }}
            onClick={handleDrawerClose}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <Stack flexDirection={"row"} justifyContent="space-between">
                <div>
                  <img
                    src={imgheaders}
                    alt="logo hub technologie"
                    style={{
                      width: 100,
                      height: 50,
                      //   marginLeft: -100,
                      objectFit:"contain"
                    }}
                  />
                </div>

                <div
                  style={{
                    marginLeft: 100,
                  }}
                >
                  <ChevronLeftIcon fontSize="large" sx={{ mt: 1 }} />
                </div>
              </Stack>
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ width: "100%", bgcolor: "#192C56" }} />

        <List>
          {dataItemDrawer.map((text, index) => (
            <ListItem key={text.id} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  // minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  mt: -1,
                }}
                onClick={() => naviga(text.links, text.title)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {text.icons}
                </ListItemIcon>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <ListItemText
                    primary={text.title}
                    sx={{
                      opacity: open ? 1 : 0,
                      fontFamily: "Montserrat",
                      fontSize: 50,
                      fontWeight: "bolder",
                    }}
                  />
                  {text.title === "Paramètre" ? (
                    open1 === false ? (
                      <ArrowDropDown
                        sx={{
                          fontSize: 25,
                          color: "#192C56",
                        }}
                      />
                    ) : (
                      <ArrowDropUp
                        sx={{
                          fontSize: 25,
                          color: "#192C56",
                        }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </Stack>
              </ListItemButton>

              {open1 === false
                ? ""
                : text.data &&
                  text.data.map((e) => (
                    <ListItem
                      key={e.id}
                      sx={{
                        // mt: -1,
                        ml: 3,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          px: 2.5,
                          mt: -3,
                        }}
                        onClick={() => naviga2(e.link)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 1,
                            justifyContent: "center",
                          }}
                        >
                          {e.icons}
                        </ListItemIcon>
                        <ListItemText
                          primary={e.title}
                          sx={{
                            // opacity: open ? 1 : 0,
                            fontFamily: "Montserrat",
                            fontSize: 25,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </ListItem>
          ))}
        </List>
        {open === true ? (
          <div
            style={{
              margin: 10,
            }}
          >
            <img
              src={`${urlbase2}/${data.imagequipe}`}
              style={{
                width: "100%",
                height: "70%",
                borderRadius: 10,
              }}
            />
            <Typography
              textAlign={"center"}
              fontFamily={"Montserrat"}
              fontWeight={"bolder"}
            >
              {data.identite}
            </Typography>
          </div>
        ) : (
          <div></div>
        )}
      </Drawer>
      <Dialogs title="Déconnection" open={opensD} setOpen={serTopnesD}>
        <Typography>Voullez-vous, vous déconnectez ? </Typography>
        <Stack direction={"row"} spacing={2} mt={3}>
          <Boutons
            title={"Oui"}
            background={"red"}
            onClick={() => nav("/connexion")}
            hovercolor={"#E33D30"}
          />
          <Boutons
            title={"Non"}
            background={"black"}
            hovercolor={"green"}
            onClick={() => serTopnesD(!opensD)}
          />
        </Stack>
      </Dialogs>
    </Box>
  );
}
