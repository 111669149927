import React from "react";
import BarMenuTitle from "../../controller/BarMenuTitle";
import MenuSiteVitrine from "../../Menu/MenuSiteVitrine";
import { Box, Grid, Typography } from "@mui/material";
import { Apps, BlurOn } from "@mui/icons-material";
import Boutons from "../../controller/Boutons";
// import useGetProjet from "../../utils/hooks/Projet/useGetProjet";
import Mtables from "../../controller/Mtable";
import ErrorConnectionInternet from "../../utils/auther/ErrorConnection";
import InputProjets from "./InputProjet";
import Dialogs from "../../controller/Dialogs";
import useGetProjetConstruction from "../../utils/hooks/Projet/useGetProjetConstruction";
import InputProjetsConstruction from "./InputProjetConstruction";

function ProjetConstruction() {
  const {
    data,
    loading,
    error,
    coloumn,
    dataselect,
    setDataSelect,
    openDialogue,
    setOpenDialogue,
    openDelete,
    // setOpenDelete,
  } = useGetProjetConstruction({
    url: "projetconstruction",
  });
  const Nouveau = () => {
    setOpenDialogue(!openDialogue);
    setDataSelect(null);
    // setda
  };
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        // width: "100vw",
        // flexWrap: "nowrap",
        overflow:"hidden"
      }}
    >
      <MenuSiteVitrine />
      <Box
        component={"main"}
        sx={{
          // flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
          width:"80%"
        }}
      >
        <BarMenuTitle
          Title={"Projets Construction"}
          bgcolor={"#192C56"}
          icone={
            <BlurOn fontSize="large" sx={{ color: "#FFF", fontSize: 30 }} />
          }
        />
        {loading ? (
          <divi>
            <p>loading....</p>
          </divi>
        ) : error !== null ? (
          <div style={{ marginTop: 25 }}>
            <ErrorConnectionInternet message={error} />
          </div>
        ) : (
          <Grid
            container
            flexDirection={"column"}
            bgcolor={"#fff"}
            sx={{
              mt: 3,
              p: 2,
            }}
          >
            <Boutons
              title={"Ajouter"}
              width={"10%"}
              onClick={() => Nouveau()}
            />
            <Grid
              item
              sx={{
                mt: 3,
                width:"100%",
                overflow:"auto"
              }}
            >
              <Mtables data={data} columns={coloumn} />
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialogs open={openDialogue} setOpen={setOpenDialogue} title={"Projet"}>
        <InputProjetsConstruction dataselect={dataselect} />
      </Dialogs>
    </Box>
  );
}

export default ProjetConstruction;
