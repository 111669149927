import React from "react";
import BarMenuTitle from "../../controller/BarMenuTitle";
import MenuSiteVitrine from "../../Menu/MenuSiteVitrine";
import { Box, Grid, Typography } from "@mui/material";
import { Apps } from "@mui/icons-material";
import TabsCategorieImage from "./TabCategorieImage";

function ImageCategorie() {
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MenuSiteVitrine />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <BarMenuTitle
          Title={"Gestion des Images et Catégories"}
          bgcolor={"#192C56"}
          icone={<Apps fontSize="large" sx={{ color: "#FFF", fontSize: 30 }} />}
        />
        <Grid container flexDirection={"column"} mt={2} p={2} bgcolor={"#fff"}>
          <TabsCategorieImage />
        </Grid>
      </Box>
    </Box>
  );
}

export default ImageCategorie;
