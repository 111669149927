import { useState, useEffect } from "react";
import { request, urlbase2 } from "../controller/Request";
import pesonneiconu from "../img/pesonneiconu.webp";
import Carousel from "react-elastic-carousel";
import { Card, Divider, Grid, Link, Stack, Typography } from "@mui/material";
// import { StackRow82, MyCard5, StackRow81 } from "../controller/Troute";
import { LinkedIn, Twitter } from "@mui/icons-material";

export const Team = (props) => {
  const [datas, setDatas] = useState([]);
  const getProprieteEntreprise = () => {
    request
      .get("equipe")
      .then((e) => {
        setDatas(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getProprieteEntreprise();
  }, []);

  const breakpoint = [
    {
      itemsToShow: 3,
    },
  ];

  return (
    <div data data-aos="zoom-in-up">
      <Grid
        container
        sx={{
          bgcolor: "#FFF",
          justifyContent: "center",
          p: 5,
        }}
        display={{
          xs: "none",
          // sm: "none",
          md: "flex",
          sm: "none",
          lg: "flex",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: -3,
              mb: 2,
            }}
          >
            EQUIPE
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 18,
              ml: 35,
              fontFamily: "Montserrat",
              mt: 2,
              mb: 5,
              width: "65%",
            }}
          >
            Rencontrez l’équipe dévouée à rendre effective la résolution des
            problèmes de la communauté au moyen de la technologie.
          </Typography>
          <Stack
            sx={{
              justifyContent: "center",
            }}
          >
            <Carousel breakPoints={breakpoint}>
              {datas
                ? datas.map((d, i) => (
                    <div
                      key={`${d.identite}-${i}`}
                      className="col-md-12 col-sm-12 team"
                    >
                      <div className="thumbnail">
                        {" "}
                        <img
                          src={`${
                            d.imagequipe === "" ? pesonneiconu : urlbase2
                          }${d.imagequipe}`}
                          alt="..."
                          className="team-img"
                          style={{
                            width: 350,
                            height:350,
                            borderRadius: 350,
                            // resize: "contain",
                            objectFit: "contain",
                          }}
                        />
                        <Divider
                          sx={{
                            bgcolor: "#DDDDDD",
                            mt: 2,
                            width: "100%",
                          }}
                        />
                        <div className="caption">
                          <Typography
                            textAlign={"center"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            variant="h5"
                            fontWeight={"bolder"}
                            fontFamily={"Montserrat"}
                          >
                            {d.identite}
                          </Typography>
                          <Typography
                            textAlign={"center"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            variant="h6"
                            fontFamily={"Montserrat"}
                            // fontWeight={"bolder"}
                          >
                            {d.postequipe}
                          </Typography>
                          <Stack
                            direction={"row"}
                            spacing={2}
                            justifyContent={"center"}
                          >
                            <Link href={d.likend}>
                              <LinkedIn
                                fontSize="large"
                                sx={{
                                  color: "#1C2E58",
                                }}
                              />
                            </Link>
                            <Link href={d.twiteer}>
                              <Twitter
                                fontSize="large"
                                sx={{
                                  // bgcolor: "blue",
                                  color: "#1C2E58",
                                }}
                              />
                            </Link>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  ))
                : "loading"}
            </Carousel>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          bgcolor: "#FFF",
          justifyContent: "center",
          // p: 5,
        }}
        display={{
          // xs: "none",
          // sm: "none",
          md: "none",
          sm: "none",
          lg: "none",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 1,
              mb: 2,
            }}
          >
            EQUIPE
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 18,
              ml: 2,
              fontFamily: "Montserrat",
              // justifyContent: "center",
              mr: 2,
              mb: 5,
            }}
          >
            Rencontrez l’équipe dévouée à rendre effective la résolution des
            problèmes de la communauté au moyen de la technologie.
          </Typography>
          <Stack
            sx={{
              justifyContent: "center",
              m: 2,
            }}
            direction="column"
          >
            {datas
              ? datas.map((d, i) => (
                  <Card
                    sx={{
                      justifyContent: "center",
                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        mt: 3,
                      }}
                    >
                      <img
                        src={`${d.imagequipe === "" ? pesonneiconu : urlbase2}${
                          d.imagequipe
                        }`}
                        alt="..."
                        className="team-img"
                        style={{
                          width: 350,
                          height: 350,
                          borderRadius: 350,
                          resize: "container",
                          resize: "contain",
                          objectFit: "contain",
                        }}
                      />
                    </Typography>
                    <Divider
                      sx={{
                        bgcolor: "#DDDDDD",
                        m: 2,
                        width: "100%",
                        // ml: 3,
                      }}
                    />
                    <Stack
                      direction={"column"}
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <h4 style={{ fontFamily: "Montserrat" }}>{d.identite}</h4>
                      <p style={{ fontFamily: "Montserrat" }}>{d.postequipe}</p>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        sx={{
                          mb: 5,
                        }}
                      >
                        <Link href={d.likend}>
                          <LinkedIn
                            fontSize="large"
                            sx={{
                              color: "#1C2E58",
                            }}
                          />
                        </Link>
                        <Link href={d.twiteer}>
                          <Twitter
                            fontSize="large"
                            sx={{
                              // bgcolor: "blue",
                              color: "#1C2E58",
                            }}
                          />
                        </Link>
                      </Stack>
                    </Stack>
                  </Card>
                ))
              : "loading"}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
