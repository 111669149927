import { AppBar, Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import BlogMenuRith from "./BlogMenuRith";
import BlogMenuLefst from "./BlogMenuLeft";
import BlogMenus from "./BlogMenu";
import ITextFieldnput from "../components/controller/TextFields";
import { Google } from "@mui/icons-material";
import AdbIcon from "@mui/icons-material/Adb";
import imgheaders from "../img/nhlogo1.png";
import { useNavigate } from "react-router-dom";

export default function Blogs() {
  const [seach, setSeach] = React.useState("");
  const nav = useNavigate();

  return (
    <div
      style={{
        bgcolor: "#f0f0f0",
        marginTop: 80,
      }}
    >
      <AppBar position="fixed" elevation={0} sx={{ background: "#fff" }}>
        <Grid container flexDirection={"row"} sx={{ bgcolor: "#fff" }} m={1}>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
            <span
              onClick={() => nav("/")}
              style={{
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  mt: 1,
                  fontSize: 25,
                  ml: 10,
                  fontWeight: "bold",
                  color: "#1C2E58",
                  textAlign: "start",
                }}
              >
                HebTeck
              </Typography>
            </span>
          </Grid>
          <Grid item xs={0} sm={0} md={5} lg={5} xl={5}>
            <ITextFieldnput
              value={seach}
              onChange={(e) => setSeach(e.target.value)}
              placeholder={"Recherche..."}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} ml={2} mt={-1}>
            <div
              style={{
                marginTop: 20,
                borderRadius: 100,
                width: "85%",
                borderWidth: 2,
                borderColor: "#192C56",
                display: "flex",
                "&:hover": {
                  backgroundColor: "#192C56",
                },
              }}
            >
              <Google
                sx={{
                  color: "#192C56",
                  // mt:1,
                  fontSize: 35,
                }}
              />
              <Button
                sx={{
                  color: "#192C56",
                  // ml: 2,
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: "bold",
                  ":hover": {
                    bgcolor: "#fff",
                  },
                }}
              >
                Continuer avec Google
              </Button>
            </div>
          </Grid>
        </Grid>
      </AppBar>
      <Grid
        container
        flexDirection={"row"}
        sx={{
          bgcolor: "#f0f0f0",
          minHeight: "100%",
        }}
      >
        <Grid item xs={0} sm={0} md={2} lg={2} xl={2}>
          <BlogMenuLefst />
        </Grid>
        <Grid item xs={0} sm={0} md={7} lg={7} xl={7}>
          <BlogMenus search={seach} />
        </Grid>
        <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
          <BlogMenuRith />
        </Grid>
      </Grid>
    </div>
  );
}
