import { useState, useEffect } from "react";
import { request, urlbase2 } from "../controller/Request";
import pesonneiconu from "../img/pesonneiconu.webp";
import { StackRow8, MyCard5 } from "../controller/Troute";
import { Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
export const Features = (props) => {
  const [datas, setDatas] = useState([]);
  const getProprieteEntreprise = () => {
    request
      .get("nosvaleur")
      .then((e) => {
        setDatas(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getProprieteEntreprise();
  }, []);
  return (
    <div data-aos="fade-up" >
      <Grid
        container
        sx={{
          bgcolor: "#EEEEF1",
          // mb: 15,
          justifyContent: "center",
          // m: 15,
          // p:10
        }}
        display={{
          xs: "none",
          // sm: "none",
          md: "flex",
          sm: "none",
          lg: "flex",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 2,
            }}
          >
            Nos valeurs
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 18,
              // ml: 5,
              fontFamily: "Montserrat",
              mt: 2,
              mb: 2,
              width: "100%",
              // ml: 35,
            }}
          >
            4 principes sur lesquels nous nous basons pour assurer l’harmonie
            aussi bien entre développeurs qu’avec nos clients.
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{
              mt: 2,
              pl:10,
              pr:10
            }}
          >
            {datas
              ? datas.slice(0, 4).map((d, i) => (
                  <Card
                    sx={{
                      m: 1,
                      mb: 10,
                      // width: "20%",
                    }}
                    elevation={0}
                  >
                    <Typography
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        p: 1,
                        m: 1,
                      }}
                    >
                      <img
                        src={`${d.logovaleur === "" ? pesonneiconu : urlbase2}${
                          d.logovaleur
                        }`}
                        style={{
                          width: 250,
                          height: 250,
                          objectFit: "contain",
                        }}
                      />
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        p: 1,
                        m: 1,
                        mt:-3
                      }}
                      variant="h4"
                    >
                      {d.titre}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        p: 1,
                        m: 1,
                        mt:-2,
                        fontSize:13
                      }}
                      // variant="h5"
                    >
                      {d.designation.toLowerCase()}
                    </Typography>
                  </Card>
                ))
              : "Loading..."}
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          bgcolor: "#EEEEF1",
          pb: 5,
          justifyContent: "center",
          // m: 15,
        }}
        display={{
          // xs: "none",
          // sm: "none",
          md: "none",
          sm: "none",
          lg: "none",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 2,
            }}
          >
            Nos valeurs
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 18,
              ml: 2,
              fontFamily: "Montserrat",
              // justifyContent: "center",
              mr: 2,
            }}
          >
            4 principes sur lesquels nous nous basons pour assurer l’harmonie
            aussi bien entre développeurs qu’avec nos clients.
          </Typography>
          <Stack
            direction={"column"}
            justifyContent="center"
            sx={{
              mt: 2,
            }}
          >
            {datas
              ? datas.slice(0, 4).map((d, i) => (
                  <Card
                    sx={{
                      m: 1,
                      // mb: 10,
                      // width: "20%",
                    }}
                    elevation={0}
                  >
                    <Typography
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        p: 1,
                        m: 1,
                      }}
                    >
                      <img
                        src={`${d.logovaleur === "" ? pesonneiconu : urlbase2}${
                          d.logovaleur
                        }`}
                        style={{
                          width: 250,
                          height: 250,
                          objectFit:"contain"
                        }}
                      />
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        p: 1,
                        m: 1,
                        mt:-3
                      }}
                      variant="h4"
                    >
                      {d.titre}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Montserrat",
                        // p: 1,
                        m: 1,
                        fontSize: 18,
                        mt:-2,
                        mb:4
                      }}
                      // variant="h5"
                    >
                      {d.designation}
                    </Typography>
                  </Card>
                ))
              : "Loading..."}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
