import React from "react";
import { Route, Routes } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./Routes";
import ProtectRoutes from "./ProtectedRoute";

export default function indexs() {
  return (
    <Routes>
      {
        //public de route
      }
      <Route>
        {publicRoutes.map((e) => (
          <Route path={e.path} element={e.component} exact={e.exact} />
        ))}
      </Route>
      {
        //potection de route
      }
      <Route element={<ProtectRoutes />}>
        {protectedRoutes.map((e) => (
          <Route path={e.path} element={e.component} />
        ))}
      </Route>
    </Routes>
  );
}
