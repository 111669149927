import React from "react";
import { request } from "../../../../controller/Request";
import { useState } from "react";
import useGetChoire from "./useGetChoire";

function useActionPourquoiNousChoire() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getData } = useGetChoire({ url: "whychooseue" });

  const Save = ({ data = null, selectedImage = null }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("designation", data.designation);
    formd.append("titre", data.titre);
    formd.append("file", selectedImage);
    request
      .post("whychooseue/save", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };
  const Update = ({
    data = null,
    selectedImage = null,
    idnosvaleur = null,
  }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("designation", data.designation);
    formd.append("titre", data.titre);
    formd.append("file", selectedImage);
    formd.append("idchoix", idnosvaleur);

    request
      .put("whychooseue/update", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };

  const ActiveUtilisateur = ({
    idequipes = null,
    setAnnuller,
    openCollapse,
  }) => {
    setLoading(true);
    const data = idequipes;
    request
      .put(`equipe/desableoractivable1/${data}`)
      .then((e) => {
        getData();
        setMessage(e.data.message);
        setLoading(false);
        setAnnuller(!openCollapse);
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error?.response?.data.message);
        }
      });
  };

  return {
    message: message,
    loading: loading,
    save: Save,
    update: Update,
    setMessage: setMessage,
    setLoading: setLoading,
    ActiveUtilisateur: ActiveUtilisateur,
  };
}

export default useActionPourquoiNousChoire;
