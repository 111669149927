import { Grid, Typography } from "@mui/material";
import React from "react";
import errorimg from "../img/errors404.png";
import Boutons from "../components/controller/Boutons";
import { useNavigate } from "react-router-dom";

function PageError() {
  const nav = useNavigate();
  const goHome = () => {
    nav("/");
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      // spacing={10}
      direction={"column"}
      sx={{
        // height: "500%",
        bgcolor: "#FFF",
      }}
    >
      <img
        src={errorimg}
        style={{
          width: "25%",
          height: "25%",
        }}
        onResize="contain"
        alt="photo"
      />
      <Typography
        sx={{
          fontSize: 100,
          fontWeight: "bold",
        }}
      >
        404
      </Typography>
      <Typography
        sx={{
          fontSize: 25,
          fontWeight: "bold",
          mt: -2,
        }}
      >
        Page Not Fund
      </Typography>
      <Boutons
        variant={"text"}
        title={"Acceuil"}
        background={"blue"}
        onClick={() => goHome()}
      />
    </Grid>
  );
}

export default PageError;
