import { Stack } from "@mui/material";
import React from "react";
import Select from "react-select";

function ComboBox({ dataTableau, value, onChange, label, icon }) {
  return (
    <Stack flexDirection={"row"} width={"100%"} mt={2}>
      <div>{icon}</div>
      <div
        style={{
          width: "100%",
        }}
      >
        <Select
          options={dataTableau}
          placeholder={label}
          value={value}
          onChange={onChange}
          isSearchable
          aria-errormessage="nll"
          position="top"
          styles={{
            option: (styles) => {
              return {
                ...styles,
                color: "#192C56",
                fontSize: 18,
                fontFamily: "Montserrat",
                backgroundColor: "#FFF",
              };
            },
          }}
        />
      </div>
    </Stack>
  );
}

export default ComboBox;
