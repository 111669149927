import { ChevronRight, DoneAll } from "@mui/icons-material";
import { Button, Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState, useEffect } from "react";
import { request } from "../controller/Request";
import imgservice from "../img/Service 24_7-pana.png";
import { StackRow, MyCard, MyTypography } from "../controller/Troute";

export const Services = (props) => {
  const [datas, setDatas] = useState([]);
  const getProprieteEntreprise = () => {
    request
      .get("service")
      .then((e) => {
        setDatas(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getProprieteEntreprise();
  }, []);
  return (
    <div data-aos="fade-right">
      <Grid
        container
        direction={"row"}
        sx={{
          mt: 5,
        }}
        display={{
          xs: "none",
          // sm: "none",
          md: "flex",
          sm: "none",
          lg: "flex",
        }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
            }}
          >
            Nos Services
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 19,
              ml: 10,
              mt: 0,
              mb: 2,
              fontFamily: "Montserrat",
            }}
          >
            Nous vous accompagnons tout au long du processus de votre
            transformation digitale en fournissant des solutions adaptées à vos
            besoins.
          </Typography>
          <Stack
            sx={{
              ml: "8%",
              flexWrap: "wrap",
              display: "flex",
            }}
            direction="row"
            // spacing={2}
          >
            {datas.slice(0, 6).map((e) => {
              return (
                <Card
                  sx={{
                    p: 3,
                    // mb: 2,
                    // width: "50%",
                    m: 1,
                  }}
                  elevation={1}
                >
                  <Typography
                    sx={{
                      fontSize: 16,
                      textAlign: "center",
                      fontFamily:"Montserrat"
                    }}
                  >
                    {e.description}
                  </Typography>
                </Card>
              );
            })}
          </Stack>
          <Stack
            sx={{
              m: 2,
              ml: 5,
            }}
            flexDirection={"row"}
            justifyContent={"end"}
          >
            <Button
              sx={{
                p: 1,
                fontSize: 18,
                color: "#1C2E58",
                // bgcolor: "#1C2E58",
                ":hover": {
                  textDecoration: "underline",
                  background: "none",
                },
              }}
              variant="text"
            >
              nos services
              <ChevronRight fontSize="large" />
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            ml: 10,
          }}
        >
          <img
            src={imgservice}
            alt="header image"
            style={{
              width: "90%",
              height: "90%",
              marginLeft: "10%",
              objectFit:"contain"
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row"}
        sx={{
          mt: 5,
        }}
        display={{
          md: "none",
          lg: "none",
        }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
            }}
          >
            Nos Services
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 18,
              m: 2,
              fontFamily: "Montserrat",
            }}
          >
            Nous vous accompagnons tout au long du processus de votre
            transformation digitale en fournissant des solutions adaptées à vos
            besoins.
          </Typography>
          <Stack
            sx={{
              // ml: 1,
              // flexWrap: "wrap",
              // display: "flex",
              justifyContent: "center",
            }}
            direction="column"
            // spacing={2}
          >
            {datas.slice(0, 3).map((e) => {
              return (
                <Card
                  sx={{
                    p: 3,
                    // mb: 2,
                    // width: "30%",
                    m: 1,
                  }}
                  elevation={1}
                >
                  <Typography
                    sx={{
                      fontSize: 18,
                      textAlign: "center",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {e.description}
                  </Typography>
                </Card>
              );
            })}
          </Stack>
          <Stack
            sx={{
              m: 1,
              // ml: 5,
            }}
          >
            <Button
              sx={{
                p: 1,
                fontSize: 18,
                color: "#1C2E58",
                // bgcolor: "#1C2E58",
                ":hover": {
                  textDecoration: "underline",
                  background: "none",
                },
              }}
              variant="text"
            >
              nos services
              <ChevronRight fontSize="large" />
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={
            {
              // ml: 10,
            }
          }
        >
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            {
              /*
              <img
              src={imgservice}
              alt="header image"
              style={{
                width: "80%",
                height: "80%",
                marginLeft: "10%",
                objectFit:"contain"
              }}
            />
              */
            }
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
