import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

function TitleHeader({ title, desicription, widthtexte }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        bgcolor: "#1C2E58",
        height: 150,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          color:"#fff"
        }}
        fontSize={40}
        fontWeight={"bold"}
        textAlign={"center"}
        mt={3}
      >
        {title}
      </Typography>
      <Stack width={"100%"} justifyContent={"center"} flexDirection={"row"}>
        <Typography
          fontSize={16}
          fontFamily={"Montserrat"}
          // fontWeight={"bold"}
          textAlign={"center"}
          // mt={5}
          color={"#fff"}
          // bgcolor={"red"}
          width={widthtexte ? widthtexte : "50%"}
        >
          {desicription}
        </Typography>
      </Stack>
    </Grid>
  );
}

export default TitleHeader;
