import React from "react";
import { request } from "../../../../controller/Request";
import { useState } from "react";
// import useGetNosValeur from "./useGetNosValeur";
import useGetImageGestions from "./useGestImageGestions";

function useActionImageCategorie() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getData } = useGetImageGestions({ url: "photohub" });

  const Save = ({
    data = null,
    selectedImage = null,
    fkidcategorie = null,
  }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("fkidcategorie", fkidcategorie);
    formd.append("title", data.title);
    formd.append("file", selectedImage);
    request
      .post("photohub/save", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };
  const Update = ({
    data = null,
    selectedImage = null,
    idnosvaleur = null,
    fkidcategorie = null,
  }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("fkidcategorie", fkidcategorie);
    formd.append("title", data.title);
    formd.append("file", selectedImage);
    formd.append("idgallery", idnosvaleur);

    request
      .put("photohub/update", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };

  const ActiveUtilisateur = ({
    idequipes = null,
    setAnnuller,
    openCollapse,
  }) => {
    setLoading(true);
    const data = idequipes;
    request
      .put(`equipe/desableoractivable1/${data}`)
      .then((e) => {
        getData();
        setMessage(e.data.message);
        setLoading(false);
        setAnnuller(!openCollapse);
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error?.response?.data.message);
        }
      });
  };

  return {
    message: message,
    loading: loading,
    save: Save,
    update: Update,
    setMessage: setMessage,
    setLoading: setLoading,
    ActiveUtilisateur: ActiveUtilisateur,
  };
}

export default useActionImageCategorie;
