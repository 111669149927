import React from "react";
import { Navigation } from "../navigation";
import { Card, Grid, Typography } from "@mui/material";
import TextFields from "../controller/TextFields";
import { Key, Person, Person2 } from "@mui/icons-material";
import Boutons from "../controller/Boutons";
// import ThemeColor from "../controller/ThemColor";
import imgheaders from "../../img/HT2.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { request, requests } from "../../controller/Request";

function Logine() {
  const [showpassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const nav = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: Yup.object({
      login: Yup.string().required("le champs est obligatoire"),
      password: Yup.string().required("le mot de passe est obligatoire"),
    }),
    onSubmit: (e) => {
      // console.log(e);
      requests
        .post("auth/login", {
          phone: e.login,
          passwords: e.password,
        })
        .then((e) => {
          console.log( "==> :)", e.data.jwt);
          localStorage.setItem("token",e.data.jwt)
          localStorage.setItem("isAuth", true);
          localStorage.setItem("data", JSON.stringify(e.data.data));

          nav("/choix");
        })
        .catch((error) => {
          console.log("Error:)",error)
          // if (error.code) {
          //   setMessage(error.response.data.message);
          // } else {
          //   setMessage(error.response.data.message);
          // }
        });
    },
  });

  useEffect(() => {
    localStorage.setItem("isAuth", false);
  }, []);

  return (
    <div>
      <Navigation />
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"#F6F6F6"}
        sx={{
          minHeight: "100vh",
        }}
      >
        <Card
          sx={{
            width: "40%",
            // height: "20%",
            // position: "absolute",
            justifyContent: "center",
            alignContent: "center",
            padding: 5,
            borderRadius: 2,
            boxShadow: "1px 2px 25px 1px black",
            justifyItems: "center",
            alignItems: "center",
            display: {
              xs: "none",
              // sm: "none",
              md: "inline",
              sm: "none",
              lg: "inline",
            },
          }}
        >
          <p>{message}</p>
          <Typography textAlign={"center"}>
            <img
              src={imgheaders}
              alt="logo neurohub"
              style={{
                width: "25%",
                height: "25%",
                // marginTop: -15,
                marginLeft: 20,
              }}
            />
          </Typography>
          <Typography
            textAlign={"center"}
            variant="h4"
            fontFamily={"Montserrat"}
            fontWeight={"bold"}
          >
            Connexion
          </Typography>
          <Typography
            // textAlign={"center"}
            mt={2}
            sx={{
              fontSize: 14,
            }}
            fontFamily={"Montserrat"}
          >
            Entre les coordonnés ci-dessous pour vous identifier
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return null;
            }}
          >
            <TextFields
              label={"Login"}
              placeholder={"Entre login"}
              id={"login"}
              value={validation.values.login}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              // error={
              //   validation.touched.login && validation.values.login
              //     ? true
              //     : false
              // }
              icons={
                <Person
                  sx={{ mr: 1, my: 0.5, color: "#192C56", fontSize: 25 }}
                  fontSize="large"
                />
              }
            />
            {validation.touched.login && validation.values.login ? (
              <span color="#F07178">{""}</span>
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validation.errors.login}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Password"}
              visi
              placeholder={"password...."}
              type={showpassword ? "text" : "password"}
              id={"password"}
              value={validation.values.password}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              // error={
              //   validation.touched.password && validation.values.password
              //     ? true
              //     : false
              // }
              btShowpwd={() => setShowPassword(!showpassword)}
              icons={
                <Key
                  sx={{ mr: 1, my: 0.5, color: "#192C56", fontSize: 25 }}
                  fontSize="large"
                />
              }
            />
            {validation.touched.password && validation.values.password ? (
              <span color="#F07178">{""}</span>
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validation.errors.password}
                </Typography>
              </div>
            )}
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Boutons
                width={"95%"}
                ml={3.5}
                background={"#192C56"}
                title={"Connexion"}
                textColor={"#FFF"}
                hovercolor={"black"}
                type={"submit"}
              />
            </div>
          </form>
        </Card>
        {/*
          mobile
          */}
        <Card
          sx={{
            m: 1,
            justifyContent: "center",
            alignContent: "center",
            padding: 5,
            borderRadius: 2,
            boxShadow: "1px 2px 25px 1px black",
            justifyItems: "center",
            alignItems: "center",
            display: {
              xs: "inline",
              // sm: "none",
              md: "none",
              sm: "inline",
              lg: "none",
            },
          }}
        >
          <Typography textAlign={"center"}>
            <img
              src={imgheaders}
              alt="logo neurohub"
              style={{
                width: "25%",
                height: "25%",
                // marginTop: -15,
                marginLeft: 20,
              }}
            />
          </Typography>
          <Typography
            textAlign={"center"}
            variant="h4"
            fontFamily={"Montserrat"}
            fontWeight={"bold"}
          >
            Connexion
          </Typography>
          <Typography
            // textAlign={"center"}
            mt={2}
            sx={{
              fontSize: 14,
            }}
            fontFamily={"Montserrat"}
          >
            Entre les coordonnés ci-dessous pour vous identifier
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return null;
            }}
          >
            <TextFields
              label={"Login"}
              placeholder={"login...."}
              id={"login"}
              value={validation.values.login}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              error={
                validation.touched.login && validation.values.login
                  ? validation.errors
                  : ""
              }
              icons={
                <Person
                  sx={{ mr: 1, my: 0.5, color: "blue" }}
                  fontSize="large"
                />
              }
            />
            <TextFields
              label={"Password"}
              visi
              placeholder={"password...."}
              type={showpassword ? "text" : "password"}
              id={"password"}
              value={validation.values.password}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              error={
                validation.touched.password && validation.values.password
                  ? true
                  : false
              }
              btShowpwd={() => setShowPassword(!showpassword)}
              icons={
                <Key sx={{ mr: 1, my: 0.5, color: "blue" }} fontSize="large" />
              }
            />
            <div
              style={{
                marginTop: 10,
              }}
            >
              <Boutons
                width={"95%"}
                ml={3.5}
                background={"blue"}
                title={"Connexion"}
                textColor={"#FFF"}
                hovercolor={"black"}
                type={"submit"}
              />
            </div>
          </form>
        </Card>
      </Grid>
    </div>
  );
}

export default Logine;
