import { NetworkCell, NetworkCheck } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";

function ErrorConnectionInternet({ message = null }) {
  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <NetworkCheck sx={{ color: "#013266" }} fontSize="large" />
      <Typography
        fontSize={20}
        fontWeight={"bold"}
        sx={{
          fontFamily: "Montserrat",
          fontSize: 20,
        }}
      >
        Error de la connextion
      </Typography>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: 18,
          textAlign: "center",
        }}
      >
        {message}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: 18,
          textAlign: "center",
        }}
      >
        {message}
        Veuillez Verifier si vous êtes belle bien connecter, après veuillez
        actualiser la pasge svp
      </Typography>
    </Grid>
  );
}

export default ErrorConnectionInternet;
