import PrincipaleEcran from "../components/PrincipaleEcran";
import ChoixActive from "../components/page/ChoixActive";
import Logine from "../components/page/Logine";
import Entreprise from "../components/page/SiteVitrineGestion/Entreprise";
import Equipe from "../components/page/SiteVitrineGestion/Equipe";
import HomeApp from "../components/page/SiteVitrineGestion/HomeApp";
import NosServices from "../components/page/SiteVitrineGestion/NosServices";
import NosValeurs from "../components/page/SiteVitrineGestion/NosValeurs";
import Partenaires from "../components/page/SiteVitrineGestion/Partenaires";
import { Image } from "../components/image";
import PourquoiNousChoisir from "../components/page/SiteVitrineGestion/PourquoiNousChoir";
// import { Partenaire } from "../components/partenaire";
import PageError from "./PageError";
import ImageCategorie from "../components/page/SiteVitrineGestion/ImageCategorie";
import { Projet } from "../components/projets";
import ProjetAdmin from "../components/page/SiteVitrineGestion/ProjetAdmin";
import Blogs from "../blog/BlogsMenuss";
import Contacter from "../components/utils/Contacter";
import ProjetConstruction from "../components/page/SiteVitrineGestion/ProjetConstruction";
import ProjetConstListImage from "../components/page/SiteVitrineGestion/ProjetConstListImage";
import ProgetListeImageConstruction from "../components/ProgetListeImageConstruction";

export const protectedRoutes = [
  //dashboard
  { path: "/choix", component: <ChoixActive /> },
  { path: "/homeapp", component: <HomeApp /> },
  { path: "/teams", component: <Equipe /> },
  { path: "/entreprise", component: <Entreprise /> },
  { path: "/nos-valeur", component: <NosValeurs /> },
  { path: "/nos-services", component: <NosServices /> },
  { path: "/pour-quoi-nous-choisir", component: <PourquoiNousChoisir /> },
  { path: "/partenaires", component: <Partenaires /> },
  { path: "/utile", component: <ImageCategorie /> },
  { path: "/admin-projet", component: <ProjetAdmin /> },
  { path: "/admin-projet-construction", component: <ProjetConstruction /> },
  {path:"/liste-projet-construction",component:<ProjetConstListImage/>},
  
];

export const publicRoutes = [
  { path: "/", component: <PrincipaleEcran /> },
  { path: "/connexion", component: <Logine /> },
  { path: "/gallery", component: <Image /> },
  { path: "/realisation", component: <Projet /> },
  { path: "/blogs", component: <Blogs /> },
  { path: "/contact", component: <Contacter /> },
  { path: "/error", component: <PageError /> },
  {path:"/suite-de-image-construction/:id",component:<ProgetListeImageConstruction/>}
];
