import { Grid } from "@mui/material";
import React from "react";
import ErrorConnectionInternet from "../../utils/auther/ErrorConnection";
import Boutons from "../../controller/Boutons";
import Mtables from "../../controller/Mtable";
import useGetImageGestions from "../../utils/hooks/ImagesGestion/useGestImageGestions";
import InputImageGestion from "./InputImageGest";
import Dialogs from "../../controller/Dialogs";

export default function ImageGestion() {
  const {
    data,
    loading,
    error,
    coloumn,
    dataselect,
    setDataSelect,
    openDialogue,
    setOpenDialogue,
    openDelete,
    setOpenDelete,
  } = useGetImageGestions({
    url: "photohub",
  });
  const Nouveau = () => {
    setOpenDialogue(!openDialogue);
    setDataSelect(null);
    // setda
  };
  return (
    <Grid container flexDirection={"column"}>
      {loading ? (
        <divi>
          <p>loading....</p>
        </divi>
      ) : error !== null ? (
        <div style={{ marginTop: 25 }}>
          <ErrorConnectionInternet message={error} />
        </div>
      ) : (
        <Grid container flexDirection={"column"} bgcolor={"#fff"}>
          <Boutons title={"Ajouter"} width={"20%"} onClick={() => Nouveau()} />
          <Grid
            item
            sx={{
              mt: 3,
            }}
          >
            <Mtables data={data} columns={coloumn} />
          </Grid>
        </Grid>
      )}

      <Dialogs
        open={openDialogue}
        setOpen={setOpenDialogue}
        title={"Catégories"}
        size={"sm"}
      >
        <InputImageGestion dataselect={dataselect} />
      </Dialogs>
    </Grid>
  );
}
