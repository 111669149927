import React, { useState } from "react";
import BarMenuTitle from "../../controller/BarMenuTitle";
import MenuSiteVitrine from "../../Menu/MenuSiteVitrine";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Add, BlurOn, ChevronLeft, Refresh } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { urlbase2 } from "../../../controller/Request";
import Dialogs from "../../controller/Dialogs";
import InputProjetsConstruction from "./InputProjetConstructionImage";
import useGetProjetConstImage from "../../utils/hooks/Projet/useGetProjetConstImage";

function ProjetConstListImage() {
  const [openDialogue, setOpenDialogue] = useState(false);
  const { data } = useLocation().state;
  const nav = useNavigate();
  const [dataUpdate, setDataUpdate] = useState();
  //   console.log("daa===>:)", data);
  const {
    datax,
    loading,
    error,
    dataselect,
    setDataSelect,
    getData,
    openDelete,
    // setOpenDelete,
  } = useGetProjetConstImage({
    url: `projetconstructionimage/${data?.idprojetconst}`,
  });

  const updateFile = (e) => {
    setOpenDialogue(!openDialogue);
    setDataUpdate(e);
  };

  const CreateFile = () => {
    setDataUpdate(null);
    setOpenDialogue(!openDialogue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        overflow: "hidden",
      }}
    >
      <MenuSiteVitrine />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <BarMenuTitle
          Title={"Liste des Image du projet"}
          bgcolor={"#192C56"}
          icone={
            <BlurOn fontSize="large" sx={{ color: "#FFF", fontSize: 30 }} />
          }
        />
        <div>
          <div>
            <IconButton onClick={() => nav(-1)}>
              <ChevronLeft fontSize="large" />
            </IconButton>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <img
              src={`${urlbase2}${data.photo}`}
              style={{
                width: 350,
                height: 350,
                objectFit: "contain",
              }}
              alt={data.title}
            />
            <div
              style={{
                marginTop: 60,
                marginLeft: 80,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 30,
                  fontFamily: "Montserrat",
                }}
              >
                {data.title}
              </Typography>
              <Typography
                sx={{
                  marginTop: 0,
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  width: "30%",
                }}
              >
                {data.description}
              </Typography>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Montserrat",
                fontSize: 18,
              }}
              endIcon={<Add />}
              onClick={() => CreateFile()}
            >
              Ajouter
            </Button>
            <IconButton onClick={() => getData()}>
              <Refresh fontSize="large" />
            </IconButton>
          </div>
          <div
            style={{
              margin: 5,
            }}
          >
            {datax.length === 0 ? (
              <div>
                <Typography
                  style={{
                    fontSize: 20,
                    fontFamily: "Montserrat",
                    textAlign: "center",
                  }}
                >
                  Ouf pas d'image veillez ajouter{" "}
                </Typography>
              </div>
            ) : (
              <div style={{ margin: 10 }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {datax.map((e) => {
                    return (
                      <Card
                        style={{
                          width: 300,
                          margin: 5,
                        }}
                      >
                        <img
                          src={`${urlbase2}/${e.image}`}
                          style={{
                            width: 300,
                            height: 250,
                            objectFit: "contain",
                          }}
                          alt={e.title}
                        />
                        <CardContent>
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat",
                              fontSize: 18,
                              fontWeight: 700,
                            }}
                          >
                            {e.title}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            sx={{
                              fontFamily: "Montserrat",
                              fontSize: 14,
                            }}
                            onClick={() => updateFile(e)}
                          >
                            Modification
                          </Button>
                        </CardActions>
                      </Card>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div>
            <Dialogs
              open={openDialogue}
              setOpen={setOpenDialogue}
              title={"Ajouter les images lie au projet"}
            >
              <InputProjetsConstruction data={data} dataselect={dataUpdate} />
            </Dialogs>
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default ProjetConstListImage;
