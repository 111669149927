import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import TextFields from "../../controller/TextFields";
import Boutons from "../../controller/Boutons";
import {
  Close,
  Email,
  GitHub,
  GpsFixed,
  LinkedIn,
  Numbers,
  Person,
  Phone,
  PostAdd,
  Twitter,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadAndDisplayImage from "../../controller/UploaderImage";
import { urlbase2 } from "../../../controller/Request";
import useAction from "../../utils/hooks/teams/useAction";
import { useState } from "react";
import { Stack } from "@mui/system";
import useActionEntreprise from "../../utils/hooks/Entreprise/useActionEntreprise";

export default function InputEntreprise({ dataselect }) {
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { message, loading, save, update, setMessage, setLoading } =
    useActionEntreprise();
  const [loading1, setLoading1] = useState(true);

  const validate = useFormik({
    enableReinitialize: false,
    initialValues: {
      rccm: dataselect?.rccm,
      idnational: dataselect?.idnational,
      designation: dataselect?.designation,
      description: dataselect?.description,
      slogan: dataselect?.slogan,
      adresse: dataselect?.adresse,
      phone1: dataselect?.phone1,
      phone2: dataselect?.phone2,
      email: dataselect?.email,
    },
    validationSchema: Yup.object().shape({
      rccm: Yup.string().required("le rccm est obligatoire"),
      idnational: Yup.string().required(
        "l'identification nationnal est obligatoire"
      ),
      designation: Yup.string().required(
        "le nom de l'entreprise et obligatoire"
      ),
      description: Yup.string().required("la description est obligatoire"),
      slogan: Yup.string().required("le slogan et obligatoire"),
      adresse: Yup.string().required("l'addresse est bligastoire"),
      phone1: Yup.string().required("le numéro de téléphone est obligatoire"),
      phone2: Yup.string().required("le numéro de téléphone est obligatoire"),
      email: Yup.string().required("email est obligatoire").email(),
    }),
    onSubmit: (e) => {
      if (dataselect === null) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          save({ data: e, selectedImage: selectedImage });
        }
      }
      if (dataselect) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          update({
            data: e,
            selectedImage: selectedImage,
            identreprise: dataselect?.identreprise,
          });
        }
      }
    },
  });

  const closeMessage = () => {
    setLoading1(true);
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading1 === true ? (
        ""
      ) : (
        <Typography
          variant="h6"
          textAlign={"center"}
          sx={{ boxShadow: "1px 2px 25px 1px red", mt: 2 }}
          fontWeight={"bold"}
          fontFamily={"Montserrat"}
          color={"#AFAFAF"}
          borderRadius={2}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            m={2}
            alignItems={"center"}
          >
            {message}
            <IconButton onClick={() => closeMessage()}>
              <Close />
            </IconButton>
          </Stack>
        </Typography>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          validate.handleSubmit();
          return null;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <div
            style={{
              marginTop: 70,
              // marginBottom: -20,
            }}
          >
            {UploadImage()}
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={5} md={5} sm={12}>
            <TextFields
              label={"Designation de l'entreprise"}
              id={"designation"}
              value={validate.values.designation}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.identite && validate.values.identite
              //       ? true
              //       : false
              //   }
              icons={
                <Person
                  sx={{
                    fontSize: 30,
                    // mt: -10,
                    color: "#192C56",
                  }}
                  fontSize="large"
                />
              }
            />
            {validate.touched.designation && validate.values.designation ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.designation}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Description"}
              id={"description"}
              value={validate.values.description}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              multiline={true}
              //   error={
              //     validate.touched.postequipe && validate.values.postequipe
              //       ? true
              //       : false
              //   }
              icons={
                <PostAdd
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.description && validate.values.description ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.description}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Slogan"}
              id={"slogan"}
              value={validate.values.slogan}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              multiline={true}
              //   error={
              //     validate.touched.likend && validate.values.likend ? true : false
              //   }
              //   type={"url"}
              icons={
                <LinkedIn
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.slogan && validate.values.slogan ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.slogan}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Localisation de l'entreprise"}
              id={"adresse"}
              value={validate.values.adresse}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              //   type={"url"}
              multiline={true}
              icons={
                <GpsFixed
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.adresse && validate.values.adresse ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.adresse}
                </Typography>
              </div>
            )}
          </Grid>

          <Grid
            sx={{
              marginLeft: 2,
            }}
            item
            xs={12}
            xl={6}
            md={6}
            sm={12}
          >
            <TextFields
              label={"Numéro de téléphone"}
              id={"phone1"}
              value={validate.values.phone1}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.twiteer && validate.values.twiteer
              //       ? true
              //       : false
              //   }
              //   type={"url"}
              icons={
                <Phone
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.phone1 && validate.values.phone1 ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.phone1}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Deuxieme numéro de telephone"}
              id={"phone2"}
              value={validate.values.phone2}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              //   type={"url"}
              icons={
                <Phone
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.phone2 && validate.values.phone2 ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.phone2}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Email"}
              id={"email"}
              value={validate.values.email}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              type={"email"}
              icons={
                <Email
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.email && validate.values.email ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.email}
                </Typography>
              </div>
            )}
            <TextFields
              label={"RCCM"}
              id={"rccm"}
              value={validate.values.rccm}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              //   type={"url"}
              icons={
                <Numbers
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.rccm && validate.values.rccm ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.rccm}
                </Typography>
              </div>
            )}
            <TextFields
              label={"ID Nationnal"}
              id={"idnational"}
              value={validate.values.idnational}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              //   type={"url"}
              icons={
                <Numbers
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.idnational && validate.values.idnational ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.idnational}
                </Typography>
              </div>
            )}
            <div
              style={{
                marginTop: 20,
                width: "100%",
                // background: "red",
                marginLeft: 29,
              }}
            >
              <Boutons
                title={dataselect === null ? "Enregistrer" : "Modification"}
                width={"90%"}
                type={"submit"}
                disabled={loading}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
