import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { dataMenuDashBord } from "../../data/Data";
import { Popover, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BasicMenu({ anchorEl, setAnchorEl, ref }) {
  const nav = useNavigate();
  const handleClose = (e) => {
    // nav(e);
    setAnchorEl(!anchorEl);
  };
  const handleClose2 = (e) => {
    nav(e);
    setAnchorEl(!anchorEl);
  };

  const id = anchorEl ? "simple-popover" : undefined;
  const open = Boolean(anchorEl);

  return (
    <Popover
      // ref={ref}
      elevation={0}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Menu
        // ref={ref}
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{color:"#fff"}}
        // MenuListProps={{
        //   "aria-labelledby": "basic-button",
        // }}
      >
        {dataMenuDashBord.map((e) => (
          <Stack flexDirection={"row"} p={1}>
            {e.image}
            <MenuItem
              sx={{
                fontSize: 18,
                fontFamily: "Montserrat",
              }}
              onClick={() => handleClose2(e.link)}
            >
              {e.title}
            </MenuItem>
          </Stack>
        ))}
      </Menu>
    </Popover>
  );
}
