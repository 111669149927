import React from "react";
import { request, urlbase2 } from "../../../../controller/Request";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Chip, IconButton, Typography } from "@mui/material";
import { Add, Delete, Edit, Label, Verified } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function useGetProjetConstImage({ url = "" }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataselect, setDataSelect] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
//   const nav=useNavigate()

  const getData = () => {
    setLoading(true);
    setError(null);
    request
      .get(url)
      .then((e) => {
        setLoading(false);
        setData(e.data.data);
        setError(null);
      })
      .catch((error) => {
        console.log(error);
        if (error.code) {
          setLoading(false);
          setError(error.message);
        } else {
          setLoading(false);
          //   setError(error.response.data.message);
        }
      });
  };


  useEffect(() => {
    getData();
  }, []);

  return {
    datax: data,
    loading: loading,
    error: error,
    dataselect: dataselect,
    setDataSelect: setDataSelect,
    openDialogue: openDialogue,
    setOpenDialogue: setOpenDialogue,
    openDelete: openDelete,
    setOpenDelete: setOpenDelete,
    getData: getData,
  };
}
