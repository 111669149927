import { Collapse, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Boutons from "../../controller/Boutons";
import useAction from "../hooks/teams/useAction";

export default function DeleteF({ nomutilisateur, openCollapse, setAnnuller }) {
  const { ActiveUtilisateur } = useAction();

  const ActiveDesableUtilisateur = () => {
    ActiveUtilisateur({
      idequipes: nomutilisateur?.idequipe,
      openCollapse: openCollapse,
      setAnnuller: setAnnuller,
    });
  };

  return (
    <div>
      <Collapse
        in={openCollapse}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          //   background: "red",
          p: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Montserrat",
            fontWeight: "bold",
          }}
        >
          Voullez-vous {nomutilisateur.etats === false ? "Active" : "desactive"}{" "}
          l'utilisateur {nomutilisateur?.identite} ?
        </Typography>
        <Stack flexDirection={"row"} mt={2}>
          <Boutons
            title={"Supprimer"}
            background={"red"}
            width={"25%"}
            onClick={() => ActiveDesableUtilisateur()}
          />
          <div style={{ marginLeft: 10, width: "100%" }}>
            <Boutons
              title={"Annuler"}
              width={"25%"}
              onClick={() => setAnnuller(!openCollapse)}
            />
          </div>
        </Stack>
      </Collapse>
    </div>
  );
}
