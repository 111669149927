import image from "../img/HT2.png";
import imagse from "../img/errors404.png";
import imagsex from "../img/bgImage.png";
import {
  PictureAsPdf,
  GraphicEq,
  Dashboard,
  Person,
  HomeMax,
  Numbers,
  RoomService,
  CheckroomSharp,
  Person2,
  Settings,
  UTurnLeft,
  ArrowCircleRight,
} from "@mui/icons-material";

export const dataMenuNavigation = [
  {
    id: 1,
    title: "Acceuil",
    link: "/",
  },
  {
    id: 2,
    title: "Realisations",
    link: "/realisation",
  },
  // {
  //   id: 3,
  //   title: "Blog",
  //   link: "/blogs",
  // },
];

export const DashboardShoixActivite = [
  {
    id: 1,
    title: "Gestion Stcok",
    image: image,
    link: "",
  },
  {
    id: 2,
    title: "Site vitrine",
    image: imagse,
    link: "/homeapp",
  },
];

export const dataMenuDashBord = [
  {
    title: "Gallery",
    id: 1,
    link: "/gallery",
    image: (
      <PictureAsPdf
        fontSize="large"
        sx={{
          mt: 1.5,
          color: "#192C56",
        }}
      />
    ),
  },
  {
    title: "Nos projets",
    id: 2,
    link: "/projet",
    image: (
      <GraphicEq
        fontSize="large"
        sx={{
          mt: 1.5,
          color: "#192C56",
        }}
      />
    ),
  },
];

export const dataItemDrawer = [
  {
    id: 1,
    title: "Home",
    icons: (
      <Dashboard
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/homeapp",
  },
  {
    id: 2,
    title: "Equipe",
    icons: (
      <Person
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/teams",
  },
  {
    id: 3,
    title: "Entreprise",
    icons: (
      <HomeMax
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/entreprise",
  },
  {
    id: 4,
    title: "Nos valeur",
    icons: (
      <Numbers
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/nos-valeur",
  },
  {
    id: 5,
    title: "Nos services",
    icons: (
      <RoomService
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/nos-services",
  },
  {
    id: 6,
    title: "Pourquoi nous choisire",
    icons: (
      <CheckroomSharp
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/pour-quoi-nous-choisir",
  },
  {
    id: 7,
    title: "Partenaire",
    icons: (
      <Person2
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    links: "/partenaires",
  },
  {
    id: 8,
    title: "Paramètre",
    icons: (
      <Settings
        fontSize="small"
        sx={{
          color: "#192C56",
          fontSize: 25,
        }}
      />
    ),
    // links: "/partenaires",
    data: [
      {
        id: 1,
        title: "Utiles",
        icons: (
          <ArrowCircleRight
            fontSize="small"
            sx={{
              color: "#192C56",
              fontSize: 18,
            }}
          />
        ),
        link: "/utile",
      },
      {
        id: 2,
        title: "Projet",
        icons: (
          <ArrowCircleRight
            fontSize="small"
            sx={{
              color: "#192C56",
              fontSize: 18,
            }}
          />
        ),
        link: "/admin-projet",
      },

      {
        id: 3,
        title: "Projet construction",
        icons: (
          <ArrowCircleRight
            fontSize="small"
            sx={{
              color: "#192C56",
              fontSize: 18,
            }}
          />
        ),
        link: "/admin-projet-construction",
      },
    ],
  },
];

export const dataImage = [
  {
    id: 1,
    images: imagsex,
  },
  {
    id: 2,
    images: imagsex,
  },
  {
    id: 3,
    images: imagsex,
  },
  {
    id: 4,
    images: imagsex,
  },
  {
    id: 5,
    images: imagsex,
  },
  {
    id: 6,
    images: imagsex,
  },
  {
    id: 7,
    images: imagsex,
  },
  {
    id: 8,
    images: imagsex,
  },
  {
    id: 9,
    images: imagsex,
  },
  {
    id: 10,
    images: imagsex,
  },
  {
    id: 11,
    images: imagsex,
  },
  {
    id: 12,
    images: imagsex,
  },
];

export const dataBlog = [
  {
    id: 1,
    identite: "Nzanzu Wayire Delord",
    image: imagsex,
    title: "Trois types de pirates informatiques",
    description:
      "Pirate éthique qui recherche des vulnérabilités avec de bonnes intentions et qui les exploite dans le but de les signaler pour les corriger. Un exemple concret d'un pirate chapeau blanc serait un expert en sécurité informatique embauché par une entreprise pour évaluer la robustesse de son système de sécurité. Ce spécialiste pourrait utiliser diverses méthodes, telles que des tests de pénétration, pour identifier les vulnérabilités potentielles dans le réseau, les logiciels ou les applications de l'entreprise. Une fois les vulnérabilités identifiées, le pirate chapeau blanc fournirait des recommandations pour renforcer la sécurité et protéger l'entreprise contre les cybermenaces. En agissant de manière éthique et transparente, ce pirate informatique contribue à renforcer la sécurité des systèmes et à protéger les données sensibles des entreprises contre les cyberattaques.",
    imagearticle: image,
    date: "18-09-2022",
  },
  {
    id: 2,
    identite: "Chadrac Wakomya",
    image: imagsex,
    title: "L’avenir de la technologie : L’IA et au-delà",
    description:
      "La technologie évolue à un rythme sans précédent, transformant notre façon de vivre, de travailler et de nous connecter. L’intelligence artificielle (IA) est au cœur de cette révolution, offrant des possibilités infinies pour l’avenir. L’IA a déjà commencé à remodeler notre monde. Des assistants vocaux intelligents aux voitures autonomes, l’IA est partout. Mais ce n’est que le début. Avec l’avancement de l’apprentissage profond et du traitement du langage naturel, l’IA devient de plus en plus sophistiquée, capable de comprendre et d’interagir avec le monde d’une manière presque humaine. Mais l’IA n’est pas la seule technologie qui façonne notre avenir. La réalité virtuelle (RV) et augmentée (RA), la blockchain, l’Internet des objets (IoT) et la 5G sont autant de technologies qui promettent de transformer notre monde. La RV et la RA offrent des expériences immersives, changeant la façon dont nous jouons, apprenons et communiquons. La blockchain offre une sécurité et une transparence sans précédent, avec le potentiel de révolutionner les industries allant de la finance à la santé. L’IoT et la 5G promettent de connecter notre monde comme jamais auparavant, avec des vitesses de connexion ultra-rapides et une multitude d’appareils connectés. Alors que nous nous tournons vers l’avenir, une chose est claire : la technologie continuera à évoluer et à transformer notre monde de manière inimaginable. Et nous sommes impatients de voir ce que l’avenir nous réserve.",
    imagearticle: imagse,
    date: "18-09-2022",
  },
  {
    id: 3,
    identite: "Natalie Joel",
    image: imagsex,
    title: "L’avenir de la technologie : L’IA et au-delà",
    description:
      "La technologie évolue à un rythme sans précédent, transformant notre façon de vivre, de travailler et de nous connecter. L’intelligence artificielle (IA) est au cœur de cette révolution, offrant des possibilités infinies pour l’avenir. L’IA a déjà commencé à remodeler notre monde. Des assistants vocaux intelligents aux voitures autonomes, l’IA est partout. Mais ce n’est que le début. Avec l’avancement de l’apprentissage profond et du traitement du langage naturel, l’IA devient de plus en plus sophistiquée, capable de comprendre et d’interagir avec le monde d’une manière presque humaine. Mais l’IA n’est pas la seule technologie qui façonne notre avenir. La réalité virtuelle (RV) et augmentée (RA), la blockchain, l’Internet des objets (IoT) et la 5G sont autant de technologies qui promettent de transformer notre monde. La RV et la RA offrent des expériences immersives, changeant la façon dont nous jouons, apprenons et communiquons. La blockchain offre une sécurité et une transparence sans précédent, avec le potentiel de révolutionner les industries allant de la finance à la santé. L’IoT et la 5G promettent de connecter notre monde comme jamais auparavant, avec des vitesses de connexion ultra-rapides et une multitude d’appareils connectés. Alors que nous nous tournons vers l’avenir, une chose est claire : la technologie continuera à évoluer et à transformer notre monde de manière inimaginable. Et nous sommes impatients de voir ce que l’avenir nous réserve.",
    imagearticle: imagsex,
    date: "18-09-2022",
  },
];
