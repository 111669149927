import React, { useEffect, useState } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import { Partenaire } from "./partenaire";
import { Typography } from "@mui/material";
var Spinner = require("react-spinkit");

function PrincipaleEcran() {
  return (
    <div>
      <div>
        <Navigation />
        <Header />
        <Features />
        <Services />
        <About />
        <Partenaire />
        {/*
    <Gallery data={landingPageData.Gallery} />
    */}
        <Testimonials />
        <Team />
        <Contact />
      </div>
    </div>
  );
}

export default PrincipaleEcran;
