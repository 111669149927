import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import TextFields from "../../controller/TextFields";
import Boutons from "../../controller/Boutons";
import {
  Close,
  GitHub,
  Key,
  LinkedIn,
  Numbers,
  Password,
  Person,
  Phone,
  PostAdd,
  Twitter,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadAndDisplayImage from "../../controller/UploaderImage";
import { urlbase2 } from "../../../controller/Request";
import useAction from "../../utils/hooks/teams/useAction";
import { useState } from "react";
import { Stack } from "@mui/system";

export default function InputEquipe({ dataselect }) {
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { message, loading, save, update, setMessage, setLoading } =
    useAction();
  const [loading1, setLoading1] = useState(true);

  const validate = useFormik({
    enableReinitialize: false,
    initialValues: {
      identite: dataselect?.identite,
      phone: dataselect?.phone,
      postequipe: dataselect?.postequipe,
      likend: dataselect?.likend,
      twiteer: dataselect?.twiteer,
      github: dataselect?.github,
      niveausuivie: dataselect?.niveausuivie,
      passwords: "",
    },
    validationSchema: Yup.object().shape({
      identite: Yup.string().required("le champs et obligatoire"),
      phone: Yup.string().required("phone est obligatoire"),
      postequipe: Yup.string().required("le champs et obligatoire"),
      likend: Yup.string().required("le champs et obligatoire").url(),
      twiteer: Yup.string().required("le champs et obligatoire").url(),
      github: Yup.string().required("le champs et obligatoire").url(),
      niveausuivie: Yup.string().required(
        "le niveau de suivie est obligastoire"
      ),
    }),
    onSubmit: (e) => {
      if (dataselect === null) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          save({ data: e, selectedImage: selectedImage });
        }
      }
      if (dataselect) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          update({
            data: e,
            selectedImage: selectedImage,
            idequipe: dataselect?.idequipe,
          });
        }
      }
    },
  });

  const closeMessage = () => {
    setLoading1(true);
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading1 === true ? (
        ""
      ) : (
        <Typography
          variant="h6"
          textAlign={"center"}
          sx={{ boxShadow: "1px 2px 25px 1px red", mt: 2 }}
          fontWeight={"bold"}
          fontFamily={"Montserrat"}
          color={"#AFAFAF"}
          borderRadius={2}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            m={2}
            alignItems={"center"}
          >
            {message}
            <IconButton onClick={() => closeMessage()}>
              <Close />
            </IconButton>
          </Stack>
        </Typography>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          validate.handleSubmit();
          return null;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <div
            style={{
              marginTop: 70,
              // marginBottom: -20,
            }}
          >
            {UploadImage()}
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={5} md={5} sm={12}>
            <TextFields
              label={"Identite complet"}
              id={"identite"}
              value={validate.values.identite}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.identite && validate.values.identite
              //       ? true
              //       : false
              //   }
              icons={
                <Person
                  sx={{
                    fontSize: 30,
                    // mt: -10,
                    color: "#192C56",
                  }}
                  fontSize="large"
                />
              }
            />
            {validate.touched.identite && validate.values.identite ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.identite}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Phone"}
              id={"phone"}
              value={validate.values.phone}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.identite && validate.values.identite
              //       ? true
              //       : false
              //   }
              icons={
                <Phone
                  sx={{
                    fontSize: 30,
                    // mt: -10,
                    color: "#192C56",
                  }}
                  fontSize="large"
                />
              }
            />
            {validate.touched.phone && validate.values.phone ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.phone}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Post equipé"}
              id={"postequipe"}
              value={validate.values.postequipe}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.postequipe && validate.values.postequipe
              //       ? true
              //       : false
              //   }
              icons={
                <PostAdd
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.postequipe && validate.values.postequipe ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.postequipe}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Lien LinkendIn"}
              id={"likend"}
              value={validate.values.likend}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.likend && validate.values.likend ? true : false
              //   }
              type={"url"}
              icons={
                <LinkedIn
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.likend && validate.values.likend ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.likend}
                </Typography>
              </div>
            )}
          </Grid>

          <Grid
            sx={{
              marginLeft: 2,
            }}
            item
            xs={12}
            xl={6}
            md={6}
            sm={12}
          >
            <TextFields
              label={"Niveau de suivie"}
              id={"niveausuivie"}
              value={validate.values.niveausuivie}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              //   type={"url"}
              icons={
                <Numbers
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.niveausuivie && validate.values.niveausuivie ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.niveausuivie}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Lien Twitter"}
              id={"twiteer"}
              value={validate.values.twiteer}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.twiteer && validate.values.twiteer
              //       ? true
              //       : false
              //   }
              type={"url"}
              icons={
                <Twitter
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.twiteer && validate.values.twiteer ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.twiteer}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Lien Github"}
              id={"github"}
              value={validate.values.github}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              type={"url"}
              icons={
                <GitHub
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.github && validate.values.github ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.github}
                </Typography>
              </div>
            )}

            <TextFields
              label={"Mot de passe"}
              id={"passwords"}
              value={validate.values.passwords}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.github && validate.values.github ? true : false
              //   }
              type={"password"}
              icons={
                <Key
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.passwords && validate.values.passwords ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.passwords}
                </Typography>
              </div>
            )}

            <div
              style={{
                marginTop: 20,
                width: "100%",
                // background: "red",
                marginLeft: 29,
              }}
            >
              <Boutons
                title={dataselect === null ? "Enregistrer" : "Modification"}
                width={"90%"}
                type={"submit"}
                disabled={loading}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
