import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetProjetConstImage from "./utils/hooks/Projet/useGetProjetConstImage";
import { urlbase2 } from "../controller/Request";
import { Navigation } from "./navigation";
import { Contact } from "./contact";
import { PhotoProvider, PhotoView } from "react-photo-view";

function ProgetListeImageConstruction() {
  const [openDialogue, setOpenDialogue] = useState(false);
  // const { data } = useLocation().state;
  const location = useLocation();

  // Récupérer et décoder les données de la chaîne de requête
  const queryParams = new URLSearchParams(location.search);
  const data = {
    title: queryParams.get("title"),
    description: queryParams.get("description"),
    photo: queryParams.get("photo"),
  };
  const nav = useNavigate();
  const { id } = useParams();
  //   console.log("daa===>:)", data);
  const {
    datax,
    loading,
    error,
    dataselect,
    setDataSelect,
    getData,
    openDelete,
    // setOpenDelete,
  } = useGetProjetConstImage({
    url: `projetconstructionimage/${id}`,
  });
  return (
    <div
      style={{
        overflow: "hidden",
      }}>
      <div style={{ marginBottom: 10 }}>
        <Navigation />
      </div>
      <div>
        <IconButton onClick={() => nav(-1)} sx={{ mt: 10 }}>
          <ChevronLeft fontSize="large" />
        </IconButton>
      </div>
      <div
        style={{
          margin: 30,
        }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
          }}>
          <img
            src={`${urlbase2}${data.photo}`}
            style={{
              width: 350,
              height: 250,
              objectFit: "contain",
            }}
            alt={data.title}
          />
          <div
            style={{
              marginTop: 10,
              marginLeft: -5,
            }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 30,
                fontFamily: "Montserrat",
              }}>
              {data.title}
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                fontSize: 16,
                fontFamily: "Montserrat",
                // width: "40%",
                // background:"red"
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}>
              {data.description}
            </Typography>
          </div>
        </div>
        <Divider style={{ marginTop: 30, width: "100%", color: "#000" }} />
        <div
          style={{
            margin: 5,
          }}>
          <Typography
            style={{
              margin: 20,
              fontSize: 20,
              fontWeight: "bold",
              fontWeight: 700,
              fontFamily: "Montserrat",
            }}>
            Autres Images detailler
          </Typography>
          {datax.length === 0 ? (
            <div>
              <Typography
                style={{
                  fontSize: 20,
                  fontFamily: "Montserrat",
                  textAlign: "center",
                }}>
                Ouf pas d'image veillez ajouter{" "}
              </Typography>
            </div>
          ) : (
            <div style={{ margin: 10 }}>
              <PhotoProvider>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                  }}>
                  {datax.map((e,index) => {
                    return (
                      <PhotoView key={index} src={`${urlbase2}/${e.image}`}>
                        <Card
                          style={{
                            width: 300,
                            margin: 5,
                            cursor: "pointer",
                          }}>
                          <img
                            src={`${urlbase2}/${e.image}`}
                            style={{
                              width: 300,
                              height: 250,
                              objectFit: "contain",
                            }}
                            alt={e.title}
                          />
                          <CardContent>
                            <Typography
                              style={{
                                textAlign: "center",
                                fontFamily: "Montserrat",
                                fontSize: 18,
                                fontWeight: 700,
                              }}>
                              {e.title}
                            </Typography>
                          </CardContent>
                        </Card>
                        
                      </PhotoView>
                    );
                  })}
                </div>
              </PhotoProvider>
            </div>
          )}
        </div>
      </div>
      {
        //
      }
      <Contact />
    </div>
  );
}

export default ProgetListeImageConstruction;
