import { Button, Grid, Popover, Stack, Typography } from "@mui/material";
import { Navigation } from "./navigation";
import bgImage from "../img/bckgroundInfo.jpg";
import imageoff from "../img/dataoff.png";
import { Contact } from "./contact";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { dataImage } from "../data/Data";
import { request, urlbase2 } from "../controller/Request";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";

const useStyles = makeStyles({
  limitedLines: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 4, // Limite le texte à 3 lignes
    fontFamily: "Montserrat",
    fontSize: 14,
  },
});

function Items({ currentItems }) {
  // console.log(currentItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const nav = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Stack
        flexDirection={"row"}
        // bgcolor={"red"}
        p={4}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}

        // ml={1}
      >
        {currentItems.length === 0 ? (
          <div
            style={{
              marginTop: 50,
            }}>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                display: "flex",
              }}>
              <img
                src={imageoff}
                style={{
                  width: 350,
                  height: 350,
                  objectFit: "contain",
                }}
                alt="dataoff"
              />
            </div>

            <Typography
              sx={{
                fontSize: 20,
                fontFamily: "Montserrat",
                fontWeight: "bold",
              }}>
              Oops! aucune realisation pour l'instant ! veillez revenir
              ulterieument{" "}
            </Typography>
            <Typography textAlign={"center"}>
              <Button
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  color: "#1C2E58",
                }}
                onClick={() => nav("/")}
                startIcon={<ChevronLeft />}>
                Retour en arriere
              </Button>
            </Typography>
          </div>
        ) : (
          currentItems &&
          currentItems.map((item) => (
            <div
              style={{
                marginRight: 30,
                marginTop: 50,
                width: 350,
                cursor: "pointer",
              }}>
              <img
                src={`${urlbase2}/${item.photoprojet}`}
                style={{
                  width: 350,
                  height: 350,
                  // borderRadius: 10,
                  objectFit: "contain",
                }}
                alt={item.title}
              />
              <Typography
                fontSize={25}
                fontWeight={"bolder"}
                fontFamily={"Montserrat"}>
                {item.title.toUpperCase()}
              </Typography>
              <Typography
                className={classes.limitedLines}
                style={{
                  fontSize: 18,
                  fontFamily: "Montserrat",
                }}>
                {item.description}
              </Typography>
            </div>
          ))
        )}
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        {/*
          <Swiper
          spaceBetween={50}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {currentItems.map((item) => (
            <SwiperSlide key={item.idgallery}>
              <img
                src={`${urlbase2}/${item.photo}`}
                style={{
                  width: "100%",
                  height: "100%",
                  // borderRadius: 10,
                }}
                alt={item.designation}
              />
            </SwiperSlide>
          ))}
        </Swiper>
          */}
      </Popover>
    </div>
  );
}
function Items2({ currentItems }) {
  // console.log(currentItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const nav = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navTo = (e) => {
    const queryParams = new URLSearchParams(e).toString();
    nav(`/suite-de-image-construction/${e?.idprojetconst}?${queryParams}`);
  };
  return (
    <div>
      <Stack
        flexDirection={"row"}
        // bgcolor={"red"}
        p={4}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}

        // ml={1}
      >
        {currentItems.length === 0 ? (
          <div
            style={{
              marginTop: 50,
            }}>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                display: "flex",
              }}>
              <img
                src={imageoff}
                style={{
                  width: 350,
                  height: 350,
                  objectFit: "contain",
                }}
                alt="dataoff"
              />
            </div>

            <Typography
              sx={{
                fontSize: 20,
                fontFamily: "Montserrat",
                fontWeight: "bold",
              }}>
              Oops! aucune realisation pour l'instant ! veillez revenir
              ulterieument{" "}
            </Typography>
            <Typography textAlign={"center"}>
              <Button
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  color: "#1C2E58",
                }}
                onClick={() => nav("/")}
                startIcon={<ChevronLeft />}>
                Retour en arriere
              </Button>
            </Typography>
          </div>
        ) : (
          currentItems &&
          currentItems.map((item) => (
            <div
              style={{
                marginRight: 30,
                marginTop: 50,
                width: 350,
                cursor: "pointer",
              }}
              onClick={() => navTo(item)}>
              <img
                src={`${urlbase2}/${item.photo}`}
                style={{
                  width: 350,
                  height: 350,
                  borderRadius: 10,
                  objectFit: "contain",
                }}
                alt={item.title}
              />
              <Typography
                fontSize={25}
                fontWeight={"bolder"}
                fontFamily={"Montserrat"}
                // marginTop={-12}
              >
                {item.title.toUpperCase()}
              </Typography>
              <Typography
                className={classes.limitedLines}
                sx={{
                  width: "100%",
                  fontSize: 18,
                  fontFamily: "Montserrat",
                }}>
                {item.description}
              </Typography>
            </div>
          ))
        )}
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        {/*
          <Swiper
          spaceBetween={50}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {currentItems.map((item) => (
            <SwiperSlide key={item.idgallery}>
              <img
                src={`${urlbase2}/${item.photo}`}
                style={{
                  width: "100%",
                  height: "100%",
                  // borderRadius: 10,
                }}
                alt={item.designation}
              />
            </SwiperSlide>
          ))}
        </Swiper>
          */}
      </Popover>
    </div>
  );
}

export const Projet = ({ itemsPerPage = 6 }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = data.slice(itemOffset, endOffset);
  const currentItems2 = data2.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const [visible, setVisible] = useState(true);
  const [visible2, setVisible2] = useState(false);

  const getPhotoAll = () => {
    request
      .get("projet")
      .then((e) => {
        setData(e.data.data);
      })
      .catch((error) => {});
  };
  const getProjetCons = () => {
    request
      .get("projetconstruction")
      .then((e) => {
        setData2(e.data.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getPhotoAll();
    getProjetCons();
  }, []);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const Visiblexx = () => {
    setVisible(true);
    setVisible2(false);
  };
  const Visiblex = () => {
    setVisible2(true);
    setVisible(false);
  };
  return (
    <>
      <Navigation />
      <Grid container flexDirection={"column"} minHeight={"90%"} mt={5}>
        <Grid
          item
          sx={{
            // backgroundImage: `url(${bgImage})`,
            background: "#1C2E58",
            width: "100%",
            height: 250,
            objectFit: "cover",
          }}>
          <Typography
            color={"#fff"}
            fontSize={30}
            fontWeight={"bold"}
            fontFamily={"Montserrat"}
            p={3}
            textAlign={"center"}>
            Realisations
          </Typography>
          <Typography
            color={"#fff"}
            fontSize={18}
            // fontWeight={"bolder"}
            fontFamily={"Montserrat"}
            p={2}
            textAlign={"center"}
            mt={-3}>
            Consultez nos produits conçus dans les domaines du développement
            logiciel et de la construction.
          </Typography>
          <Stack
            flexDirection={"row"}
            justifyContent={"center"}
            alignContent={"center"}>
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "#FFF",
                borderWidth: 1,
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 16,
              }}
              onClick={() => Visiblexx()}
              disabled={visible}>
              Logiciel
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "#fff",
                borderWidth: 1,
                ml: 2,
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 16,
              }}
              onClick={() => Visiblex()}
              disabled={visible2}>
              Construction
            </Button>
          </Stack>
        </Grid>
        <Grid item justifyContent={"center"} alignItems={"center"} mt={-8}>
          <Grid
            container
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            // bgcolor={"red"}
          >
            {visible2 === true ? (
              <Items2 currentItems={currentItems2} />
            ) : (
              <Items currentItems={currentItems} />
            )}

            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </Grid>
        </Grid>
      </Grid>
      <Contact />
    </>
  );
};
