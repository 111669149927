import { Button } from "@mui/material";
import React from "react";

function Boutons({
  onClick,
  icons,
  title,
  type,
  ml,
  background,
  hovercolor,
  disabled,
  borderColor,
  textColor,
  width,
  variant,
  border,
}) {
  return (
    <Button
      variant={"outlined"}
      sx={{
        ml: ml,
        background: background ? background : "#192C56",
        borderColor: borderColor ? borderColor : "#192C56",
        // borderWidth: 0.5,
        color: textColor ? textColor : "#FFF",
        fontFamily: "Montserrat",
        fontSize: 12,
        borderRadius: 2,
        border: border ? border : "", //solid
        width: width,
        // height: 45,
        "&:hover": {
          background: hovercolor ? hovercolor : "#536180",
        },
      }}
      disabled={disabled}
      onClick={onClick}
      type={type} //submit, reset,button
    >
      <div
        style={{
          marginRight: 5,
        }}
      >
        {icons}
      </div>
      {title}
    </Button>
  );
}

export default Boutons;
