import { Card, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { request, urlbase2 } from "../controller/Request";
import pesonneiconu from "../img/pesonneiconu.webp";
import { StackRow, MyCard, MyCard00, StackRow00 } from "../controller/Troute";
import { Language, LinkedIn } from "@mui/icons-material";
import Carousel from "react-elastic-carousel";

const breakpoint = [
  {
    itemsToShow: 3,
  },
];

export const Partenaire = (props) => {
  const [datas, setDatas] = useState([]);
  const getProprieteEntreprise = () => {
    request
      .get("partenaire")
      .then((e) => {
        setDatas(e.data.data);
        // console.log(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getProprieteEntreprise();
  }, []);

  const ouvrirLienDynamique = (url) => {
    // Assurez-vous que l'URL n'est pas vide avant d'ouvrir
    if (url !== "") {
      // Ouvre le lien dynamique dans une nouvelle fenêtre ou un nouvel onglet
      window.open(url, "_blank");
      alert(url);
    } else {
      alert("le partenair n'as pas du site web");
    }
  };
  return (
    <div data-aos="flip-left">
      <Grid
        container
        display={{
          xs: "none",
          // sm: "none",
          md: "flex",
          sm: "none",
          lg: "flex",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 2,
            }}
          >
            Ils nous font confiance!
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 18,
              ml: 40,
              fontFamily: "Montserrat",
              mt: 2,
              mb: 2,
              width: "65%",
            }}
          >
            Nous vous présentons des exemples non-exhaustifs de nos
            collaborateurs ayant l’expérience de notre savoir-faire et de notre
            souci de satisfaire nos clients.
          </Typography>

          <br />
          <Stack
            direction={"row"}
            sx={{
              mt: 2,
              // justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              // ml: -5,
            }}
          >
            <Carousel breakPoints={breakpoint}>
              {datas
                ? datas.map((d, i) => (
                    <Card
                      sx={{
                        // m: 1,
                        mb: 10,
                        justifyContent: "center",
                        p: 5,
                        width: 345,
                      }}

                      // elevation={10}
                    >
                      <Typography textAlign={"center"}>
                        <img
                          src={`${
                            d.logopartenaire === "" ? pesonneiconu : urlbase2
                          }${d.logopartenaire}`}
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: "contain",//cover
                          }}
                        />
                      </Typography>
                      <Divider sx={{ mt: 2, width: "100%" }} />
                      <div className="caption">
                        <Typography
                          variant="h4"
                          fontWeight={"bold"}
                          textAlign={"center"}
                          m={1}
                        >
                          {d.desigantion}
                        </Typography>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent={"center"}
                        >
                          {
                            d.links?<Link
                            onClick={() => ouvrirLienDynamique(d.links)}
                            // href={d.likens}
                            sx={{
                              ":hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Language
                              fontSize="large"
                              sx={{
                                color: "#1C2E58",
                              }}
                            />
                          </Link>:""
                          }
                          
                        </Stack>
                      </div>
                    </Card>
                  ))
                : "Loading..."}
            </Carousel>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          bgcolor: "#FFF",
          pb: 5,
          justifyContent: "center",
          // m: 15,
        }}
        display={{
          // xs: "none",
          // sm: "none",
          md: "none",
          sm: "none",
          lg: "none",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 2,
            }}
          >
            Ils nous font confiance!
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 18,
              ml: 2,
              fontFamily: "Montserrat",
              // justifyContent: "center",
              mr: 2,
            }}
          >
            Nous vous présentons des exemples non-exhaustifs de nos
            collaborateurs ayant l’expérience de notre savoir-faire et de notre
            souci de satisfaire nos clients.
          </Typography>

          <br />
          <Stack
            direction={"column"}
            sx={{
              mt: 2,
              justifyContent: "center",
            }}
          >
            {datas
              ? datas.map((d, i) => (
                  <Card
                    sx={{
                      m: 1,
                      mb: 1,
                      // width: "0%",
                      justifyContent: "center",
                    }}
                    // elevation={0}
                  >
                    <Typography
                      sx={{
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`${
                          d.logopartenaire === "" ? pesonneiconu : urlbase2
                        }${d.logopartenaire}`}
                        style={{
                          width: "60%",
                          height: "60%",
                          objectFit: "cover",
                        }}
                      />
                    </Typography>
                    <Divider sx={{ mt: 2, width: "100%" }} />
                    <div className="caption">
                      <Typography
                        variant="h4"
                        fontWeight={"bold"}
                        textAlign={"center"}
                        m={1}
                      >
                        {d.desigantion}
                      </Typography>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                      >
                        <Link href={d.likens}>
                          <LinkedIn
                            fontSize="large"
                            sx={{
                              color: "#1C2E58",
                            }}
                          />
                        </Link>
                      </Stack>
                    </div>
                  </Card>
                ))
              : "Loading..."}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
