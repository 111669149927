import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Routers from "./router/index";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";
import LoadingBar from 'react-top-loading-bar'
import { useEffect, useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'react-photo-view/dist/react-photo-view.css';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
   const ref = useRef(null)
   useEffect(() => {
    // Démarrer la barre de chargement lorsque la page commence à se charger
    ref.current.continuousStart();

    // Compléter la barre de chargement lorsque la page est complètement chargée
    window.addEventListener('load', () => {
      ref.current.complete();
    });

    // Nettoyer l'événement lorsqu'on quitte le composant
    return () => {
      window.removeEventListener('load', () => {
        ref.current.complete();
      });
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durée de l'animation en millisecondes
      //once: true, // Animer seulement lors du premier défilement
    });
  }, []);

  return (
    <div
      style={{
        background: "#F6F6F6",
        overflow:"hidden"
      }}
    >
     <LoadingBar color='#02BEF4' ref={ref} />
      <Routers />
      
    </div>
  );
};

export default App;
