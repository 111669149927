import * as React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { red, green, blue } from "@mui/material/colors";

export const StackRow = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    marginTop: "40%",
    // marginBottom: 5
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    // backgroundColor: blue[500],
    // direction: "row",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 5,
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const StackRow00 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    marginTop: "-10%",
    // marginBottom: 5
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    // backgroundColor: blue[500],
    // direction: "row",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 5,
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const MyCard = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
    // marginTop: "20%",
    marginBottom: 15,
    backgroundColor: "#FFF",
    elevation: 30,
    marginLeft: 0,
    // borderWidth: 1,
    border: "solid 0.1em",
    textAlign: "center",
  },
  [theme.breakpoints.up("md")]: {
    width: "47%",
    justifyContent: "center",
    // marginTop: 10,
    // marginBottom: 5,
    backgroundColor: "#FFF",
    margin: theme.spacing(1),
    elevation: 10,
    // borderColor: "red",
    borderWidth: 1,
    border: "solid 0.1em",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const MyCard00 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
    marginBottom: 15,
    backgroundColor: "#FFF",
    marginLeft: 100,
  },
  [theme.breakpoints.up("md")]: {
    // width: "47%",
    justifyContent: "center",
    // marginTop: 10,
    // marginBottom: 5,
    backgroundColor: "#FFF",
    margin: theme.spacing(1),
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const MyCard2 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#FFF",
    elevation: 30,
    marginLeft: 0,
  },
  [theme.breakpoints.up("md")]: {
    // width: "47%",
    justifyContent: "center",
    marginTop: 10,
    // marginBottom: 5,
    backgroundColor: "#FFF",
    margin: theme.spacing(3),
    elevation: 10,
    // borderColor: "red",
    // borderWidth: 1,
    // border: "solid 0.1em",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const MyTypography = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    // width: "100%",
    textAlign: "justify",
    margin: 5,
    fontSize: 18,
    marginBottom: "-45%",
  },
  [theme.breakpoints.up("md")]: {
    textAlign: "justify",
    marginLeft: 40,
    marginTop: 15,
    fontSize: 18,
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));
export const MyTypography2 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    // width: "100%",
    textAlign: "justify",
    margin: 5,
    fontSize: 18,
  },
  [theme.breakpoints.up("md")]: {
    textAlign: "justify",
    marginLeft: 40,
    marginTop: 15,
    fontSize: 18,
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const GrandTitre = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    // width: "100%",
    // textAlign: "justify",
    marginTop: "25%",
    fontSize: "50px",
    marginLeft: 0,
    fontWeight: "bold",
    color: "blue",
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "25%",
    fontSize: "65px",
    marginLeft: "13%",
    fontWeight: "bold",
    color: "blue",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const SubTitre = styled("h1")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    // width: "100%",
    // textAlign: "justify",
    marginTop: "-2%",
    fontSize: "50px",
    marginLeft: 0,
    fontWeight: "bold",
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "0%",
    fontSize: "50px",
    marginLeft: "13%",
    fontWeight: "bold",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));
export const SubTitre2 = styled("h4")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    // width: "100%",
    // textAlign: "justify",
    marginTop: "5%",
    fontSize: "18px",
    marginLeft: 0,
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "0%",
    fontSize: "18px",
    marginLeft: "13%",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const StackRow2 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    marginLeft: 0,
    margin: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    // backgroundColor: blue[500],
    // direction: "row",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 5,
    marginLeft: "13%",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));

export const StackRow8 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    marginLeft: 0,
    margin: theme.spacing(1),
    width: "100%",
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    // backgroundColor: blue[500],
    // direction: "row",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10%",
    marginBottom: 5,
    marginLeft: 0,
    justifyContent: "center",
  },
  [theme.breakpoints.up("lg")]: {
    // backgroundColor: green[500],
    direction: "row",
  },
}));
export const StackRow81 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    marginLeft: 0,
    margin: theme.spacing(1),
    width: "100%",
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
    flexDirection: "row",
    display: "none",
    marginTop: "10%",
    marginBottom: 5,
    marginLeft: 0,
    justifyContent: "center",
  },
  [theme.breakpoints.up("lg")]: {
    direction: "row",
  },
}));
export const StackRow82 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "none",
    marginLeft: 0,
    margin: theme.spacing(1),
    width: "100%",
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
    flexDirection: "row",
    display: "flex",
    marginTop: "10%",
    marginBottom: 5,
    marginLeft: 0,
    justifyContent: "center",
  },
  [theme.breakpoints.up("lg")]: {
    direction: "row",
  },
}));

export const MyCard5 = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    width: "94%",
    justifyContent: "center",
    backgroundColor: "#FFF",
    elevation: 30,
    marginLeft: 0,
    margin: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "center",
    backgroundColor: "#FFF",
    margin: theme.spacing(3),
    elevation: 10,
    width: "20%",
  },
  [theme.breakpoints.up("lg")]: {
    direction: "row",
  },
}));
