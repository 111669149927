import React from "react";
import { request } from "../../../../controller/Request";
import { useState } from "react";
import useGetTPartenaire from "./useGetPartenaire";

function useActionPartenaire() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getData } = useGetTPartenaire({ url: "partenaire" });

  const Save = ({ data = null, selectedImage = null }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("desigantion", data.desigantion);
    formd.append("secteur", data.secteur);
    formd.append("file", selectedImage);
    formd.append("links", data.links);

    request
      .post("partenaire/save", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        console.log(":)",error);
        // setMessage(error.response.data.message);
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };
  const Update = ({
    data = null,
    selectedImage = null,
    idpartenaire = null,
  }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("desigantion", data.desigantion);
    formd.append("secteur", data.secteur);
    formd.append("file", selectedImage);
    formd.append("links", data.links);
    formd.append("idpartenaire", idpartenaire);

    request
      .put("partenaire/update", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };

  const ActiveUtilisateur = ({
    idequipes = null,
    setAnnuller,
    openCollapse,
  }) => {
    setLoading(true);
    const data = idequipes;
    request
      .put(`equipe/desableoractivable1/${data}`)
      .then((e) => {
        getData();
        setMessage(e.data.message);
        setLoading(false);
        setAnnuller(!openCollapse);
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error?.response?.data.message);
        }
      });
  };

  return {
    message: message,
    loading: loading,
    save: Save,
    update: Update,
    setMessage: setMessage,
    setLoading: setLoading,
    ActiveUtilisateur: ActiveUtilisateur,
  };
}

export default useActionPartenaire;
