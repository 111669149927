import { Card, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState, useEffect } from "react";
import { request, urlbase2 } from "../controller/Request";
import imgpourquoichoisir from "../img/Product quality-pana.png";
import pesonneiconu from "../img/pesonneiconu.webp";
import { StackRow, MyCard, MyTypography2 } from "../controller/Troute";

export const About = (props) => {
  const [datas, setDatas] = useState([]);
  const getProprieteEntreprise = () => {
    request
      .get("whychooseue")
      .then((e) => {
        setDatas(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getProprieteEntreprise();
  }, []);
  return (
    <div data-aos="fade-left">
      <Grid
        container
        sx={{
          // mt: -10,
          bgcolor: "#EEEEF1",
          width:"100%"
        }}
        display={{
          xs: "none",
          // sm: "none",
          md: "flex",
          sm: "none",
          lg: "flex",
        }}
      >
        <Grid item xs={12} md={5}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 3,
              ml:7
            }}
          >
            Pourquoi nous choisir?
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 18,
              ml: 7,
              mt: 2,
              mb: 2,
              fontFamily: "Montserrat",
            }}
          >
            Grâce à notre expertise et à notre expérience dans le domaine du
            digital, nous saisissons précisément ce dont vous avez besoin et
            parvenons à implémenter les meilleures solutions y adaptées et ainsi
            augmenter et pérenniser vos performances dans le temps.
          </Typography>
          <img
            src={imgpourquoichoisir}
            alt="header image"
            style={{
              width: "90%",
              height: "50%",
              // marginBottom: "25%",
              objectFit:"contain"
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            ml: 5,
            mt: 5,
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {datas.slice(0, 4).map((e) => {
              return (
                <Card
                  sx={{
                    width: "45%",
                    m: 1,
                  }}
                >
                  <Typography
                    sx={{
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`${e.logochoose === "" ? pesonneiconu : urlbase2}${
                        e.logochoose
                      }`}
                      style={{
                        width: 100,
                        height: 100,
                        margin: 10,
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 25,
                      mt: 3,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {e.titre}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      mt: 3,
                      fontSize: 16,
                      fontFamily: "Montserrat",
                      mb: 5,
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    {e.designation}
                  </Typography>
                </Card>
              );
            })}
          </Stack>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          // mt: -10,
          bgcolor: "#EEEEF1",
        }}
        display={{
          // xs: "none",
          // sm: "none",
          md: "none",
          sm: "none",
          lg: "none",
        }}
      >
        <Grid item xs={12} md={5}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 40,
              fontFamily: "Montserrat",
              mt: 5,
            }}
          >
            Pourquoi nous choisir?
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: 18,
              m: 2,
              fontFamily: "Montserrat",
              display: "-webkit-box",
              WebkitLineClamp: 5, // Limite à 4 lignes
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            }}
          >
            Grâce à notre expertise et à notre expérience dans le domaine du
            digital, nous saisissons précisément ce dont vous avez besoin et
            parvenons à implémenter les meilleures solutions y adaptées et ainsi
            augmenter et pérenniser vos performances dans le temps.
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            <img
              src={imgpourquoichoisir}
              alt="header image"
              style={{
                width: "90%",
                height: "50%",
                // marginBottom: "5%",
              }}
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          // sx={{
          //   ml: 5,
          //   mt: 5,
          // }}
        >
          <Stack
            direction={"column"}
            sx={{
              // display: "flex",
              // flexWrap: "wrap",
              justifyContent: "center",
              mt: 5,
              mb: 10,
            }}
          >
            {datas.slice(0, 4).map((e) => {
              return (
                <Card
                  sx={{
                    // width: "45%",
                    m: 1,
                  }}
                  // elevation={10}
                >
                  <Typography
                    sx={{
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`${e.logochoose === "" ? pesonneiconu : urlbase2}${
                        e.logochoose
                      }`}
                      style={{
                        width: 100,
                        height: 100,
                        margin: 10,
                      }}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 25,
                      mt: 3,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {e.titre}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      mt: 3,
                      fontSize: 16,
                      fontFamily: "Montserrat",
                      mb: 5,
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    {e.designation}
                  </Typography>
                </Card>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
