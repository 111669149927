import { Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function BarMenuTitle({ icone, Title, bgcolor }) {
  return (
    <Grid
      container
      direction={"column"}
      sx={{
        width: "100%",
        // background: "#F5F5F5"
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          p: 2,
          bgcolor: bgcolor,
          borderRadius: 2,
        }}
      >
        <Stack
          direction={"row"}
          divider={
            <Divider
              orientation="vertical"
              sx={{ bgcolor: "#FFF", width: 2 }}
              flexItem
            />
          }
          spacing={1}
        >
          {icone}
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#FFF",
            }}
          >
            {Title}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default BarMenuTitle;
