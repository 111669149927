import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import MenuSiteVitrine from "../../Menu/MenuSiteVitrine";
import BarMenuTitle from "../../controller/BarMenuTitle";
import { Edit, HomeMax } from "@mui/icons-material";
import useGetEntreprise from "../../utils/hooks/Entreprise/useGetEntreprise";
import Boutons from "../../controller/Boutons";
import Mtables from "../../controller/Mtable";
import ErrorConnectionInternet from "../../utils/auther/ErrorConnection";
import { Stack } from "@mui/system";
import { urlbase2 } from "../../../controller/Request";
import Dialogs from "../../controller/Dialogs";
import InputEntreprise from "./InputEntreprise";

function Entreprise() {
  const {
    data,
    loading,
    error,
    coloumn,
    dataselect,
    setDataSelect,
    openDialogue,
    setOpenDialogue,
    openDelete,
    setOpenDelete,
  } = useGetEntreprise({
    url: "entreprise",
  });

  const openDelets = () => {
    setOpenDialogue(!openDelete);
    setDataSelect(data);
  };
  const openDeletsB = () => {
    setOpenDialogue(!openDelete);
    setDataSelect(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MenuSiteVitrine />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <BarMenuTitle
          Title={"Entreprise"}
          bgcolor={"#192C56"}
          icone={
            <HomeMax fontSize="large" sx={{ color: "#FFF", fontSize: 30 }} />
          }
        />
        {loading ? (
          <divi>
            <p>loading....</p>
          </divi>
        ) : error !== null ? (
          <div style={{ marginTop: 25 }}>
            <ErrorConnectionInternet message={error} />
          </div>
        ) : (
          <Grid
            container
            flexDirection={"column"}
            mt={2}
            p={2}
            bgcolor={"#fff"}
          >
            {data ? (
              ""
            ) : (
              <Boutons
                title={"Nouveau"}
                background={"#192C56"}
                textColor={"#fff"}
                width={"15%"}
                onClick={() => openDeletsB()}
              />
            )}

            <Grid
              item
              sx={{
                mt: 3,
              }}
            >
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={2}
              >
                <Stack>
                  <Stack
                    flexDirection={"row"}
                    // justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img
                      src={`${urlbase2}/${data?.logoentreprise}`}
                      style={{
                        width: "30%",
                        height: "30%",
                      }}
                      alt="image entreprise"
                    />
                    <div
                      style={{
                        marginLeft: 25,
                      }}
                    >
                      <Typography
                        fontSize={20}
                        fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.designation}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.phone1}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.phone2}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.email}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.adresse}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.rccm}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.idnational}
                      </Typography>
                      <Typography
                        fontSize={14}
                        // fontWeight={"bold"}
                        fontFamily={"Montserrat"}
                      >
                        {data?.description}
                      </Typography>
                    </div>
                  </Stack>
                  <Typography
                    fontSize={16}
                    // fontWeight={"bold"}
                    fontFamily={"Montserrat"}
                  >
                    {data?.slogan}
                  </Typography>
                </Stack>
                <Stack mt={-40}>
                  <IconButton
                    size="medium"
                    sx={{
                      bgcolor: "#192C56",
                    }}
                    onClick={() => openDelets()}
                  >
                    <Edit fontSize="large" sx={{ color: "#fff" }} />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialogs
        open={openDialogue}
        setOpen={setOpenDialogue}
        title={"Entreprise"}
        size={"md"}
      >
        <InputEntreprise dataselect={dataselect} />
      </Dialogs>
    </Box>
  );
}

export default Entreprise;
