import React from "react";
import { request } from "../../../../controller/Request";
import { useState } from "react";
import useGetCategories from "./useGetCategorie";

function useActionCatgorie() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getData } = useGetCategories({ url: "categorie" });

  const Save = ({ data = null }) => {
    setLoading(true);

    request
      .post("categorie/save", {
        designation: data.designation,
      })
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };
  const Update = ({ data = null, idnosvaleur = null }) => {
    setLoading(true);
    request
      .put("categorie/update", {
        designation: data.designation,
        idcategoriephoto: idnosvaleur,
      })
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };

  const ActiveUtilisateur = ({
    idequipes = null,
    setAnnuller,
    openCollapse,
  }) => {
    setLoading(true);
    const data = idequipes;
    request
      .put(`equipe/desableoractivable1/${data}`)
      .then((e) => {
        getData();
        setMessage(e.data.message);
        setLoading(false);
        setAnnuller(!openCollapse);
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error?.response?.data.message);
        }
      });
  };

  return {
    message: message,
    loading: loading,
    save: Save,
    update: Update,
    setMessage: setMessage,
    setLoading: setLoading,
    ActiveUtilisateur: ActiveUtilisateur,
  };
}

export default useActionCatgorie;
