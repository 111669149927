import React from "react";
import { request, urlbase2 } from "../../../../controller/Request";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Chip, IconButton, Typography } from "@mui/material";
import { Delete, Edit, Label, Verified } from "@mui/icons-material";
import { Stack } from "@mui/system";

export default function useGetImageGestions({ url = "" }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataselect, setDataSelect] = useState(null);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const column = [
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Titre
        </Typography>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Image
        </Typography>
      ),
      selector: (row) => (
        <img
          src={`${urlbase2}/${row.photo}`}
          style={{
            width: 50,
            height: 50,
            borderRadius: 100,
          }}
        />
      ),
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Catégories
        </Typography>
      ),
      selector: (row) => row.tcategoriephoto.designation,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Date Creation
        </Typography>
      ),
      selector: (row) => row.datecreate,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Date modification
        </Typography>
      ),
      selector: (row) => row.dateupdate,
      sortable: true,
    },
    {
      name: (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Action
        </Typography>
      ),
      selector: (row) => (
        <Stack flexDirection={"row"}>
          <IconButton onClick={() => openDialogueS(row)}>
            <Edit fontSize="large" color="info" />
          </IconButton>
          {/*
            <IconButton onClick={() => openDelets(row)}>
            <Delete fontSize="large" color="error" />
          </IconButton>
            */}
        </Stack>
      ),
      sortable: true,
    },
  ];

  const getData = () => {
    setLoading(true);
    setError(null);
    request
      .get(url)
      .then((e) => {
        setLoading(false);
        setData(e.data.data);
        setError(null);
      })
      .catch((error) => {
        // console.log(error);
        if (error.code) {
          setLoading(false);
          setError(error.message);
        } else {
          setLoading(false);
          //   setError(error.response.data.message);
        }
      });
  };

  const openDialogueS = (row) => {
    setOpenDialogue(!openDialogue);
    setDataSelect(row);
  };
  const openDelets = (row) => {
    setOpenDelete(!openDelete);
    setDataSelect(row);
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    data: data,
    loading: loading,
    error: error,
    coloumn: column,
    dataselect: dataselect,
    setDataSelect: setDataSelect,
    openDialogue: openDialogue,
    setOpenDialogue: setOpenDialogue,
    openDelete: openDelete,
    setOpenDelete: setOpenDelete,
    getData: getData,
  };
}
