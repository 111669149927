import { Grid, Typography, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { request, urlbase2 } from "../controller/Request";
import pesonneiconu from "../img/pesonneiconu.webp";
import Carousel from "react-multi-carousel";
import imageoff from "../img/dataoff.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Testimonials = (props) => {
  const [datas, setDatas] = useState([]);
  const getProprieteEntreprise = () => {
    request
      .get("projetconstructionimage")
      .then((e) => {
        setDatas(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getProprieteEntreprise();
  }, []);
  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: "#EEEEF1",
        }}
        display={{
          xs: "none",
          // sm: "none",
          md: "flex",
          sm: "none",
          lg: "flex",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 30,
              fontFamily: "Montserrat",
              mt: 3,
              mb: 5,
            }}
          >
            Organisation des Travaux de Construction
          </Typography>
          <div style={{ margin: 5 }}>
            <Carousel
              responsive={responsive}
              transitionDuration={3000}
              slidesToSlide={1}
              swipeable={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
            >
              {datas.length !== 0 ? (
                datas.map((d, i) => (
                  <div key={i}>
                    <img
                      style={{
                        width: 500,
                        height: 345,
                        objectFit: "contain",
                      }}
                      alt="image"
                      src={`${urlbase2}/${d.image}`}
                    />
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 30,
                        // fontWeight: "bold",
                        textAlign: "center",
                        marginTop: -20,
                      }}
                    >
                      {d.title}
                    </Typography>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    marginLeft:"100%"
                  }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <img
                      src={imageoff}
                      style={{
                        width: 150,
                        height: 150,
                        objectFit: "contain",
                      }}
                      alt="dataoff"
                    />
                  </div>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontFamily: "Montserrat",
                      textAlign: "center",
                    }}
                  >
                    Oops! Aucune information pour l'instant
                  </Typography>
                </div>
              )}
            </Carousel>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          bgcolor: "#EEEEF1",
        }}
        display={{
          // xs: "none",
          // sm: "none",
          md: "none",
          sm: "none",
          lg: "none",
        }}
      >
        <Grid item xs={12} md={12} sm={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 18,
              fontFamily: "Montserrat",
              mt: 3,
              mb: 2,
            }}
          >
            Organisation des Travaux de Construction
          </Typography>
          <div>
            <Carousel
              responsive={responsive}
              transitionDuration={3000}
              slidesToSlide={1}
              swipeable={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
            >
              {datas.length !== 0 ? (
                datas.map((d, i) => (
                  <div key={i}>
                    <img
                      style={{
                        width: 345,
                        height: 345,
                        objectFit: "contain",
                      }}
                      alt="image"
                      src={`${urlbase2}/${d.image}`}
                    />
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 30,
                        // fontWeight: "bold",
                        textAlign: "center",
                        marginTop: -20,
                      }}
                    >
                      {d.title}
                    </Typography>
                  </div>
                ))
              ) : (
                <div
                style={{
                  width: "100%",
                  marginLeft:"100%"
                }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <img
                    src={imageoff}
                    style={{
                      width: 150,
                      height: 150,
                      objectFit: "contain",
                    }}
                    alt="dataoff"
                  />
                </div>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontFamily: "Montserrat",
                    textAlign: "center",
                  }}
                >
                  Oops! Aucune information pour l'instant
                </Typography>
              </div>
              )}
            </Carousel>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
