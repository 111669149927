import { createTheme } from "@mui/material/styles";

let ThemeColor = createTheme({
  palette: {
    mode: "light", //light,dark
    primary: {
      main: "#000F20",
    },
    secondary: {
      main: "#fff",
    },
  },
});

export default ThemeColor;
