import React from "react";
import { request, requests } from "../../../../controller/Request";
import { useState } from "react";
import useGetNosService from "./useGetNosService";

function useActionNosServices() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getData } = useGetNosService({ url: "service" });

  const Save = ({ data = null, selectedImage = null }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("description", data.description);
    requests
      .post("service/save", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };
  const Update = ({ data = null, idservicex = null }) => {
    setLoading(true);

    requests
      .put("service/update", {
        description: data.description,
        idservice: idservicex,
      })
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };

  const ActiveUtilisateur = ({
    idequipes = null,
    setAnnuller,
    openCollapse,
  }) => {
    setLoading(true);
    const data = idequipes;
    request
      .put(`equipe/desableoractivable1/${data}`)
      .then((e) => {
        getData();
        setMessage(e.data.message);
        setLoading(false);
        setAnnuller(!openCollapse);
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error?.response?.data.message);
        }
      });
  };

  return {
    message: message,
    loading: loading,
    save: Save,
    update: Update,
    setMessage: setMessage,
    setLoading: setLoading,
    ActiveUtilisateur: ActiveUtilisateur,
  };
}

export default useActionNosServices;
