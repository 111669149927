import { Button, Grid, Popover, Stack, Typography } from "@mui/material";
import { Navigation } from "./navigation";
// import bgImage from "../img/bgImage.png";
import imageoff from "../img/dataoff.png";
import { Contact } from "./contact";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { dataImage } from "../data/Data";
import { request, urlbase2 } from "../controller/Request";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PhotoProvider, PhotoView } from "react-photo-view";
// import { Swiper, SwiperSlide } from "swiper/react";

// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems }) {
  // console.log(currentItems);
  const [anchorEl, setAnchorEl] = useState(null);
  const nav = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Stack
        flexDirection={"row"}
        // bgcolor={"red"}
        p={4}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexWrap={"wrap"}
        // ml={1}
      >
        <PhotoProvider>
          {currentItems.length === 0 ? (
            <div
              style={{
                marginTop: 50,
              }}>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                }}>
                <img
                  src={imageoff}
                  style={{
                    width: 250,
                    height: 250,
                    objectFit: "contain",
                  }}
                  alt="dataoff"
                />
              </div>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                }}>
                Oops! aucune image pour l'instant! veillez revenir ulterieument
              </Typography>
              <Typography textAlign={"center"}>
                <Button
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: 14,
                    color: "#1C2E58",
                  }}
                  onClick={() => nav("/")}
                  startIcon={<ChevronLeft />}>
                  Retour en arriere
                </Button>
              </Typography>
            </div>
          ) : (
            currentItems &&
            currentItems.map((item,index) => (
              <PhotoView key={index} src={`${urlbase2}/${item.photo}`}>
                <div style={{ marginRight: 20, marginTop: 50,  cursor: "pointer"  }}>
                  <img
                    src={`${urlbase2}/${item.photo}`}
                    style={{
                      width: 350,
                      height: 350,
                      borderRadius: 2,
                      objectFit: "contain",
                    }}
                    alt={item.title}
                  />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      fontFamily: "Montserrat",
                    }}>
                    {item.title}
                  </Typography>
                </div>
              </PhotoView>
            ))
          )}
        </PhotoProvider>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        {/*
          <Swiper
          spaceBetween={50}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {currentItems.map((item) => (
            <SwiperSlide key={item.idgallery}>
              <img
                src={`${urlbase2}/${item.photo}`}
                style={{
                  width: "100%",
                  height: "100%",
                  // borderRadius: 10,
                }}
                alt={item.designation}
              />
            </SwiperSlide>
          ))}
        </Swiper>
          */}
      </Popover>
    </div>
  );
}

export const Image = ({ itemsPerPage = 6 }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [data, setData] = useState([]);
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const getPhotoAll = () => {
    request
      .get("photohub")
      .then((e) => {
        setData(e.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getPhotoAll();
  }, []);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <>
      <Navigation />
      <Grid container flexDirection={"column"} minHeight={"90%"} mt={5}>
        <Grid
          item
          sx={{
            // backgroundImage: `url(${bgImage})`,
            width: "100%",
            height: 150,
            background: "#1C2E58",
          }}>
          <Typography
            color={"#FFF"}
            fontSize={30}
            fontWeight={"bolder"}
            fontFamily={"Montserrat"}
            p={2}
            textAlign={"center"}
            mt={3}>
            Gallery
          </Typography>
          <Typography
            color={"#fff"}
            fontSize={18}
            // fontWeight={"bolder"}
            fontFamily={"Montserrat"}
            p={2}
            textAlign={"center"}
            mt={-3}>
            Trouvez nos plus belle image
          </Typography>
        </Grid>
        <Grid item justifyContent={"center"} alignItems={"center"} mt={-8}>
          <Grid
            container
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            // bgcolor={"red"}
          >
            <Items currentItems={currentItems} />
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
            />
          </Grid>
        </Grid>
      </Grid>
      <Contact />
    </>
  );
};
