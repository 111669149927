import { Box } from "@mui/system";
import React from "react";
import MenuSiteVitrine from "../../Menu/MenuSiteVitrine";
import BarMenuTitle from "../../controller/BarMenuTitle";
import { CheckroomSharp, Numbers } from "@mui/icons-material";
import Mtables from "../../controller/Mtable";
import { Grid } from "@mui/material";
import Boutons from "../../controller/Boutons";
import ErrorConnectionInternet from "../../utils/auther/ErrorConnection";
import useGetNosValeur from "../../utils/hooks/NosValeur/useGetNosValeur";
import Dialogs from "../../controller/Dialogs";
import InputNosValeurs from "./InputNosValeurs";
import useGetChoire from "../../utils/hooks/PourquoiNousChoire/useGetChoire";
import InputPourquoiChoire from "./InputPourquoiChoisir";

function PourquoiNousChoisir() {
  const {
    data,
    loading,
    error,
    coloumn,
    dataselect,
    setDataSelect,
    openDialogue,
    setOpenDialogue,
    openDelete,
    setOpenDelete,
  } = useGetChoire({
    url: "whychooseue",
  });
  //test

  const Nouveau = () => {
    setOpenDialogue(!openDialogue);
    setDataSelect(null);
    // setda
  };
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        // flexWrap: "nowrap",
      }}
    >
      <MenuSiteVitrine />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <BarMenuTitle
          Title={"Pourquoi nous choire"}
          bgcolor={"#192C56"}
          icone={
            <CheckroomSharp
              fontSize="large"
              sx={{ color: "#FFF", fontSize: 30 }}
            />
          }
        />
        {loading ? (
          <divi>
            <p>loading....</p>
          </divi>
        ) : error !== null ? (
          <div style={{ marginTop: 25 }}>
            <ErrorConnectionInternet message={error} />
          </div>
        ) : (
          <Grid
            container
            flexDirection={"column"}
            mt={2}
            p={2}
            bgcolor={"#fff"}
          >
            <Boutons
              title={"Nouveau"}
              background={"#192C56"}
              textColor={"#fff"}
              width={"15%"}
              onClick={() => Nouveau()}
            />
            {/*
            openDelete ? (
                <DeleteF
                  setAnnuller={setOpenDelete}
                  openCollapse={openDelete}
                  nomutilisateur={dataselect}
                />
              ) : (
                ""
              )
            */}
            <Grid
              item
              sx={{
                mt: 3,
              }}
            >
              <Mtables data={data} columns={coloumn} />
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialogs
        open={openDialogue}
        setOpen={setOpenDialogue}
        title={"Pourquoi nous choire"}
      >
        <InputPourquoiChoire dataselect={dataselect} />
      </Dialogs>
    </Box>
  );
}

export default PourquoiNousChoisir;
