import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import imgheaders from "../img/nhlogo1.png";
import {
  AccessibleForward,
  BookOnline,
  ContactsTwoTone,
  EmojiEvents,
  Home,
  Login,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function TemporaryDrawer(props) {
  const nav = useNavigate();
  const NavigationL = (e) => {
    nav(e);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        // mt: 5,
      }}
      role="presentation"
      onClick={props.setOpen}
      onKeyDown={props.setOpen}
    >
      <List>
        {[
          {
            title: "Acceuil",
            icons: (
              <Home
                fontSize="large"
                sx={{
                  color: "#172139",
                }}
              />
            ),
            link: "/",
          },

          {
            title: "Realisation",
            icons: (
              <BookOnline
                fontSize="large"
                sx={{
                  color: "#172139",
                }}
              />
            ),
            link: "/realisation",
          },
        ].map((text, index) => (
          <ListItem
            key={text.title}
            disablePadding
            onClick={() => NavigationL(text.link)}
          >
            <ListItemButton>
              <ListItemIcon>{text.icons}</ListItemIcon>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: "bolder",
                }}
              >
                {text.title}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={"right"} open={props.open} onClose={props.setOpen}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              mt: 1,
              fontSize: 18,
              // ml: 10,
              fontWeight: "bold",
              color: "#1C2E58",
              textAlign: "start",
              m: 2,
            }}
          >
            Hub Technologie
          </Typography>
          <Divider sx={{width:"100%", bgcolor:"#000"}} />
          {/*
            <img
            src={imgheaders}
            alt="logo neurohub"
            style={{
              width: "90%",
              height: "15%",
              // marginTop: -15,
              marginLeft: 5,
              marginRight: 3,
              paddingTop: 25,
              paddingBottom: 10,
            }}
          />
            */}
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
