import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import TextFields from "../../controller/TextFields";
import Boutons from "../../controller/Boutons";
import {
  Close,
  GitHub,
  LinkedIn,
  Numbers,
  Person,
  PostAdd,
  Twitter,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadAndDisplayImage from "../../controller/UploaderImage";
import { urlbase2 } from "../../../controller/Request";
import useAction from "../../utils/hooks/teams/useAction";
import { useState } from "react";
import { Stack } from "@mui/system";
import useActionPartenaire from "../../utils/hooks/Partenaire/useActionPartenaire";

export default function InputPartenaire({ dataselect }) {
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { message, loading, save, update, setMessage, setLoading } =
    useActionPartenaire();
  const [loading1, setLoading1] = useState(true);

  const validate = useFormik({
    enableReinitialize: false,
    initialValues: {
      desigantion: dataselect?.desigantion,
      secteur: dataselect?.secteur,
      links: dataselect?.links,
    },
    validationSchema: Yup.object().shape({
      links: Yup.string().required("le champs et obligatoire").url(),
      desigantion: Yup.string().required("le champs et obligatoire"),
      secteur: Yup.string().required("le champs et obligatoire"),
    }),
    onSubmit: (e) => {
      if (dataselect === null) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          save({ data: e, selectedImage: selectedImage });
        }
      }
      if (dataselect) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          update({
            data: e,
            selectedImage: selectedImage,
            idpartenaire: dataselect?.idpartenaire,
          });
        }
      }
    },
  });

  const closeMessage = () => {
    setLoading1(true);
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading1 === true ? (
        ""
      ) : (
        <Typography
          variant="h6"
          textAlign={"center"}
          sx={{ boxShadow: "1px 2px 25px 1px red", mt: 2 }}
          fontWeight={"bold"}
          fontFamily={"Montserrat"}
          color={"#AFAFAF"}
          borderRadius={2}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            m={2}
            alignItems={"center"}
          >
            {message}
            <IconButton onClick={() => closeMessage()}>
              <Close />
            </IconButton>
          </Stack>
        </Typography>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          validate.handleSubmit();
          return null;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <div
            style={{
              marginTop: 70,
              // marginBottom: -20,
            }}
          >
            {UploadImage()}
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={12} md={12} sm={12}>
            <TextFields
              label={"Titre"}
              id={"desigantion"}
              value={validate.values.desigantion}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.identite && validate.values.identite
              //       ? true
              //       : false
              //   }
              icons={
                <Person
                  sx={{
                    fontSize: 30,
                    // mt: -10,
                    color: "#192C56",
                  }}
                  fontSize="large"
                />
              }
            />
            {validate.touched.desigantion && validate.values.desigantion ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.desigantion}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Secteur"}
              id={"secteur"}
              value={validate.values.secteur}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.postequipe && validate.values.postequipe
              //       ? true
              //       : false
              //   }
              icons={
                <PostAdd
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.secteur && validate.values.secteur ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.secteur}
                </Typography>
              </div>
            )}
            <TextFields
              label={"Lien du site web"}
              id={"links"}
              value={validate.values.links}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.likend && validate.values.likend ? true : false
              //   }
              type={"url"}
              icons={
                <LinkedIn
                  sx={{
                    fontSize: 30,
                    color: "#192C56",
                  }}
                />
              }
            />
            {validate.touched.links && validate.values.links ? (
              ""
            ) : (
              <div style={{ marginLeft: "10%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.links}
                </Typography>
              </div>
            )}
          </Grid>

          <div
            style={{
              marginTop: 20,
              width: "100%",
              // background: "red",
              marginLeft: 29,
            }}
          >
            <Boutons
              title={dataselect === null ? "Enregistrer" : "Modification"}
              width={"90%"}
              type={"submit"}
              disabled={loading}
            />
          </div>
        </Grid>
      </form>
    </div>
  );
}
