import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import TextFields from "../../controller/TextFields";
import Boutons from "../../controller/Boutons";
import {
  Close,
  GitHub,
  LinkedIn,
  LinkOffOutlined,
  Numbers,
  Person,
  PostAdd,
  Twitter,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadAndDisplayImage from "../../controller/UploaderImage";
import { useState } from "react";
import { Stack } from "@mui/system";
// import useActionNosValeurs from "../../utils/hooks/NosValeur/useActionNosValeurs";
// import useActionProjet from "../../utils/hooks/Projet/useactionProjet";
import useActionProjetConstruction from "../../utils/hooks/Projet/useactionProjetConstruction";
import useActionProjetConstructionImage from "../../utils/hooks/Projet/useactionProjetConstructionImage";

export default function InputProjetsConstruction({ dataselect, data }) {
  const { UploadImage, selectedImage } = UploadAndDisplayImage();
  const { message, loading, save, update, setMessage, setLoading } =
  useActionProjetConstructionImage();
  const [loading1, setLoading1] = useState(true);

  const validate = useFormik({
    enableReinitialize: false,
    initialValues: {
      //   description: dataselect?.description,
      title: dataselect?.title,
      //   urlLink:dataselect?.urlLink,
      fkidprojetconst: data?.idprojetconst,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("le champs et obligatoire"),
      //   description: Yup.string().required("le champs et obligatoire"),
    }),
    onSubmit: (e) => {
      console.log(e, dataselect?.idprojet);
      if (dataselect === null) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          save({ data: e, selectedImage: selectedImage });
        }
      }
      if (dataselect) {
        if (selectedImage === null) {
          setMessage("l'image est obligatoir");
          setLoading1(false);
        } else {
          setLoading1(false);
          update({
            data: e,
            selectedImage: selectedImage,
            idnosvaleur: dataselect?.idimageconst,
          });
        }
      }
    },
  });

  const closeMessage = () => {
    setLoading1(true);
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading1 === true ? (
        ""
      ) : (
        <Typography
          variant="h6"
          textAlign={"center"}
          sx={{ boxShadow: "1px 2px 25px 1px red", mt: 2 }}
          fontWeight={"bold"}
          fontFamily={"Montserrat"}
          color={"#AFAFAF"}
          borderRadius={2}
        >
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            m={2}
            alignItems={"center"}
          >
            {message}
            <IconButton onClick={() => closeMessage()}>
              <Close />
            </IconButton>
          </Stack>
        </Typography>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          validate.handleSubmit();
          return null;
        }}
        style={{
          width: "100%",
        }}
      >
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <div
            style={{
              marginTop: 70,
              // marginBottom: -20,
            }}
          >
            {UploadImage()}
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={12} md={12} sm={12}>
            <TextFields
              label={"Titre"}
              id={"title"}
              value={validate.values.title}
              onChange={validate.handleChange}
              onBlur={validate.handleBlur}
              //   error={
              //     validate.touched.identite && validate.values.identite
              //       ? true
              //       : false
              //   }
              icons={
                <PostAdd
                  sx={{
                    fontSize: 30,
                    // mt: -10,
                    color: "#192C56",
                  }}
                  fontSize="large"
                />
              }
            />
            {validate.touched.title && validate.values.title ? (
              ""
            ) : (
              <div style={{ marginLeft: "6%" }}>
                <Typography color={"#F07178"}>
                  {validate.errors.title}
                </Typography>
              </div>
            )}

            <div
              style={{
                marginTop: 20,
                width: "100%",
                // background: "red",
                marginLeft: 29,
              }}
            >
              <Boutons
                title={dataselect === null?"Enregistrer":"Modification"}
                width={"90%"}
                type={"submit"}
                disabled={loading}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
