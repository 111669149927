import { Menu } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import ThemeColor from "../controller/ThemColor";
import HubTechnologie from "../img/HTlarge.png";
import TemporaryDrawer from "./Drawerx";
import { dataMenuNavigation } from "../data/Data";
import { useNavigate } from "react-router-dom";
import BasicMenu from "./outher/MenuitemsHomme";
import { useRef } from "react";

export const Navigation = (props) => {
  const [open, setOpen] = useState(false);
  const nav = useNavigate();
  const ref = useRef();

  const [anchorEl, setAnchorEl] = useState(null);

  const HomeNavigation = (e, env) => {
    if (e.title === "Parametre") {
      setAnchorEl(env.currentTarget);
      // console.log(env);
    } else if (e.title === "Acceuil") {
      nav(e.link);
    } else if (e.title === "Blog") {
      nav(e.link);
    } else if (e.title === "Realisations") {
      nav(e.link);
    } else {
      nav("/");
    }
  };

  const Connexion = () => {
    nav("/connexion");
  };
  const title = localStorage.getItem("title");

  return (
    <AppBar
      sx={{
        bgcolor: "#1C2E58",
      }}
      elevation={1}
      position="fixed">
      <Toolbar>
        <Grid container>
          {/**
           * cote site web
           */}
          <Grid
            item
            xs={0}
            md={3}
            sm={3}
            lg={3}
            mt={1}
            display={{
              xs: "none",
              // sm: "none",
              md: "flex",
              sm: "none",
              lg: "flex",
            }}>
            {/*
             <img
              src={HubTechnologie}
              alt="logo neurohub"
              style={{
                width: 250,
                height:10,
                marginLeft: 0,
                paddingTop: -10,
                paddingBottom: 10,
                cursor: "pointer",
                objectFit: "contain",
              }}
              onClick={() => nav("/")}
            />
            */}

            <span
              onClick={() => nav("/")}
              style={{
                cursor: "pointer",
              }}>
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  // mt: 0.5,
                  fontSize: 25,
                  ml: 10,
                  fontWeight: "bold",
                  // color: "#1C2E58",
                  color: "#fff",
                  textAlign: "start",
                }}>
                Hub Technologie
              </Typography>
            </span>
          </Grid>

          <Grid
            item
            xs={0}
            md={6}
            sm={6}
            lg={6}
            // mt={-6}
            display={{
              xs: "none",
              // sm: "none",
              md: "flex",
              sm: "none",
              lg: "flex",
            }}>
            <Stack
              sx={{
                mt: 1,
              }}
              direction="row"
              spacing={2}
              justifyContent={"center"}
              // bgcolor={"red"}
              width={"100%"}>
              {dataMenuNavigation.map((e) => (
                <Button
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: 14,
                    fontWeight: "bolder",
                    color: "#fff",
                    ":hover": {
                      // bgcolor: "#FFF",
                      // color: "#192C56",
                      // color:"#fff"
                      textDecoration: "underLine",
                    },
                  }}
                  id={e.id}
                  variant="text"
                  onClick={(env) => HomeNavigation(e, env)}>
                  {e.title}
                </Button>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={0}
            md={3}
            sm={3}
            lg={3}
            display={{
              xs: "none",
              // sm: "none",
              md: "flex",
              sm: "none",
              lg: "flex",
            }}
            justifyContent={"end"}
            // bgcolor={"red"}
          >
            <Stack
              sx={{
                mt: 2,
              }}
              direction="row"
              spacing={2}
              // bgcolor={"yellow"}
            >
              {/*
               <Button
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: "bolder",
                  ":hover": {
                    bgcolor: "#FFF",
                    color: "#192C56",
                  },
                }}
                onClick={() => Connexion()}
              >
                Connexion
              </Button>
              */}
            </Stack>
          </Grid>

          {/**
           * cote mobile
           */}
          <Grid
            item
            xs={0}
            md={12}
            sm={12}
            display={{
              //  xs: "",
              // sm: "none",
              md: "none",
              //  sm: "none",
              lg: "none",
            }}
            width={"100%"}>
            <Stack
              direction={"row"}
              display={"flex"}
              justifyContent="space-between"
              width={"100%"}
              // bgcolor={"yellow"}
            >
              <div  onClick={() => nav("/")} >
                <Typography
                  style={{
                    fontSize: 18,
                    marginTop: 10,
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}>
                  Hub Technologie
                </Typography>
              </div>

              <div
                style={{
                  marginTop: 5,
                }}>
                <IconButton onClick={() => setOpen(!open)}>
                  <Menu fontSize="large" sx={{ color: "#fff" }} />
                </IconButton>
              </div>
            </Stack>
          </Grid>
        </Grid>
        <TemporaryDrawer open={open} setOpen={() => setOpen(!open)} />
      </Toolbar>
      <BasicMenu
        anchorEl={anchorEl}
        ref={ref.current}
        setAnchorEl={setAnchorEl}
      />
    </AppBar>
  );
};
