// import { Person } from "@mui/icons-material";
// import { AccountCircle } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  // TextField,
} from "@mui/material";
// import { Stack } from "@mui/system";
import React from "react";

function TextFields({
  label,
  value,
  onChange,
  multiline,
  type,
  placeholder,
  onBlur,
  error,
  icons,
  visi,
  id,
  btShowpwd,
}) {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Typography
        sx={{
          ml:icons? 3.8:0,
          mb: -2,
          // fontWeight: "bold",
          fontFamily: "Montserrat",
          mt: 1,
          fontSize: 14,
        }}
      >
        {label}
      </Typography>
      <Box
        width={"100%"}
        // border={2}
        // borderColor={"GrayText"}
        // borderRadius={2}
        sx={{ display: "flex", alignItems: "flex-end" }}
      >
        {icons}
        <OutlinedInput
          // typeof="passeword"

          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#FFF",
            marginTop: 1,
            // color: "#FFF",
            mt: 2,
            fontSize: 18,
            fontFamily: "Montserrat",
          }}
          id={id}
          label={label}
          value={value}
          onChange={onChange}
          size="small"
          multiline={multiline}
          rows={5}
          // variant="outlined"
          type={type}
          placeholder={placeholder}
          endAdornment={
            visi === true ? (
              <InputAdornment position="end">
                <IconButton onClick={btShowpwd}>
                  <Visibility
                    sx={{
                      color: "#192C56",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ) : (
              ""
            )
          }
          onBlur={onBlur}
          {...(error && {
            error: true,
            heelperText: error,
          })}
        />
      </Box>
    </div>
  );
}

export default TextFields;
