import { useState } from "react";
// import emailjs from "emailjs-com";
import {
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { datalink } from "../data/datalink";
import { useEffect } from "react";
import { request } from "../controller/Request";
import { Email, GpsFixed, Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [header, setHeader] = useState("");
  const nav = useNavigate();
  const getProprieteEntreprise = () => {
    request
      .get("entreprise")
      .then((e) => {
        setHeader(e.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getProprieteEntreprise();
  }, []);
  return (
    <>
      <Grid
        container
        sx={{
          p: 3,
          // bgcolor: "#EEEEF1",
          bgcolor:"#192C56",
          // ml:10
        }}
      >
        <Grid container justifyContent={"space-between"} ml={5} >
          <Grid
            item
            xs={12}
            md={2}
            // sx={{
            //   mr: 5,
            // }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              Hub Techno.
            </Typography>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
              }}
            />
            <Typography
              sx={{
                fontSize: 16,
                fontFamily: "Montserrat",
                color:"#fff"
                
              }}
            >
              Hub Technologie est une startup Des solutions numériques
              innovantes combinées à une expertise en architecture moderne
            </Typography>
          </Grid>
          <Grid item sx={12} md={3}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              ENTREPRISE
            </Typography>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
              }}
            />
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              A propos de nous
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              Nos Services
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontFamily:"Montserrat",
                color:"#fff"
              }}
            >
              Nos Valeurs
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              SUPPORT
            </Typography>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
              }}
            />
            <div onClick={() => nav("/contact")}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  color:"#fff",
                  ":hover": {
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underLine",

                  },
                }}
              >
                Contactez-nous :
              </Typography>
            </div>
            <a
              href="mailto:exemple@domaine.com"
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              {" "}
              {header.email}
            </a>

            <Stack direction={"row"} spacing={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  color:"#fff"
                }}
              >
                {header.phone1}
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  color:"#fff"
                }}
              >
                {header.phone2}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={12} md={3}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color:"#fff"
              }}
            >
              Adresse et autre
            </Typography>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
              }}
            />
            <Stack direction={"row"} spacing={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  color:"#fff"
                }}
              >
                {header.adresse}
              </Typography>
            </Stack>

            <Stack direction={"row"} spacing={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  color:"#fff"
                }}
              >
                {header.rccm}
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontFamily: "Montserrat",
                  color:"#fff"
                }}
              >
                {header.idnational}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          p: 3,
          // ml:5,
          bgcolor: "white",
        }}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: 18,
              mt: 1,
              fontFamily: "Montserrat",
              // fontWeight: "bold",
              ml:5
            }}
          >
            Copyright &copy; {new Date().getFullYear()} HubTechnologie. All
            rights reserved
            {/*
              <a href='http://www.templatewire.com' rel='nofollow'>
              TemplateWire
            </a>
              */}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} >
          <Stack ml={-10} direction={"row"} spacing={2} sx={{ justifyContent: "end" }}>
            {datalink.map((e) => {
              return (
                <IconButton sx={{ml:-10}} size="large" key={e.id}>
                  {e.icons}
                </IconButton>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
