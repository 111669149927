import { Box } from "@mui/material";
import React from "react";
import MenuSiteVitrine from "../../Menu/MenuSiteVitrine";
import BarMenuTitle from "../../controller/BarMenuTitle";
import { Home } from "@mui/icons-material";

function HomeApp() {
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        minHeight: "100vh",
        // ml: 30,
        width: "100vw",
        flexWrap: "nowrap",
      }}
    >
      <MenuSiteVitrine />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 10,
          pl: 3,
          mr: 5,
        }}
      >
        <BarMenuTitle
          Title={"Acceuil"}
          bgcolor={"#192C56"}
          icone={<Home fontSize="large" sx={{ color: "#FFF", fontSize: 30 }} />}
        />
      </Box>
    </Box>
  );
}

export default HomeApp;
