import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";

export const datalink = [
  {
    id: "1",
    icons: <LinkedIn fontSize="large" />,
    link: "",
  },
  {
    id: "2",
    icons: <Twitter fontSize="large" />,
    link: "",
  },
  {
    id: "3",
    icons: <Facebook fontSize="large" />,
    link: "",
  },
  {
    id: "4",
    icons: <Instagram fontSize="large" />,
    link: "",
  },
];
