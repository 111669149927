import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import BarMenuTitle from "../controller/BarMenuTitle";
import { Menu } from "@mui/icons-material";
import { DashboardShoixActivite } from "../../data/Data";
import { useNavigate } from "react-router-dom";

function ChoixActive() {
  const nav = useNavigate();
  return (
    <Grid
      container
      direction={"column"}
      minHeight={"100vh"}
      bgcolor={"#F6F6F6"}
      p={2}
    >
      <BarMenuTitle
        bgcolor={"blue"}
        icone={
          <Menu
            fontSize="large"
            sx={{
              color: "#fff",
            }}
          />
        }
        Title={"Menu"}
      />
      <Grid container p={2}>
        <Typography
          mt={-2}
          sx={{
            fontSize: 18,
          }}
          fontFamily={"Montserrat"}
          fontWeight={"bold"}
        >
          Quelles Activités, voullez-vous effectués ?
        </Typography>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          {/*
          oridinateur
          */}
          {DashboardShoixActivite.map((e) => (
            <Card
              id={e.id}
              sx={{
                width: "30%",
                m: 2,
                mt: 5,
                p: 5,
                display: {
                  xs: "none",
                  // sm: "none",
                  md: "inline",
                  sm: "none",
                  lg: "inline",
                  cursor: "pointer",
                  ":hover": {
                    background: "#E3E6E8",
                  },
                },
              }}
              onClick={() => nav(e.link)}
            >
              <CardMedia
                component="img"
                // height="194"
                image={e.image}
                alt="Paella dish"
                sx={{
                  resize: "contain",
                }}
              />
              <CardContent>
                <Typography
                  // mt={2}
                  sx={{
                    fontSize: 18,
                  }}
                  fontFamily={"Montserrat"}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  {e.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
          {DashboardShoixActivite.map((e) => (
            <Card
              id={e.id}
              sx={{
                // width: "30%",
                m: 0,
                mt: 1,
                p: 2,
                display: {
                  xs: "inline",
                  // sm: "none",
                  md: "none",
                  sm: "inline",
                  lg: "none",
                },
              }}
              onClick={() => nav(e.link)}
            >
              <CardMedia
                component="img"
                // height="194"
                image={e.image}
                alt="Paella dish"
                sx={{
                  resize: "contain",
                }}
              />
              <CardContent>
                <Typography
                  // mt={2}
                  sx={{
                    fontSize: 18,
                  }}
                  fontFamily={"Montserrat"}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  {e.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChoixActive;
